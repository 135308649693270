import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { folderReducer } from './folders/store/folder.reducer';
import { authReducer } from './auth/auth.reducers';
import { userEditorReducer } from './user-editor/user-editor.reducer';
import { uploadsTableReducer } from './upload/uploads-table/uploads-table.reducer';
import { expandReducer } from './folders/store/expand.reducer';
import { searchReducer } from './search/model/search.reducer';
import { AppState } from './core.store';
import { logoutError, logoutSuccess } from './auth/auth.actions';
import { organizationSettingsReducer } from './organization-settings/organization-settings.reducer';
import { annotationReducer } from './GaalServer.store';
import { jobResultsReducer } from './jobs/store/job-results/job-results.reducer';
import { featuresReducer } from '../features/feature-switch/store/features.reducer';
import { ngsReducer } from './ngs/store/ngs.reducer';
import { routerReducer } from '@ngrx/router-store';
import { reducer as documentTableEditReducer } from './document-table-edits/document-table-edits.reducer';
import { jobsStatusReducer } from './jobs/store/jobs-status/jobs-status.reducer';
import { ngsComparisonsGraphDataReducer } from './ngs/ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.reducer';
import { ngsGraphDataReducer } from './ngs/ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';
import { userSettingsReducer } from './user-settings/user-settings.reducer';

// Defines which states should be preserved between page refreshes.

export const reducers: ActionReducerMap<AppState, any> = {
  folders: folderReducer,
  authState: authReducer,
  userEditorReducer,
  folderExpands: expandReducer,
  uploadsTable: uploadsTableReducer,
  jobResults: jobResultsReducer,
  jobsStatus: jobsStatusReducer,
  searchReducer,
  annotationReducer,
  organizationSettings: organizationSettingsReducer,
  userSettings: userSettingsReducer,
  features: featuresReducer,
  ngs: ngsReducer,
  router: routerReducer,
  documentTableEdits: documentTableEditReducer,
  ngsComparisonsGraphData: ngsComparisonsGraphDataReducer,
  ngsGraphData: ngsGraphDataReducer,
};

/**
 * Slices of the store to store within and hydrate from localStorage.
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['folderExpands', 'organizationSettings', 'viewerStates'],
    rehydrate: true,
  })(reducer);
}

/**
 * Reducer that cleans the state on logout actions. It does that by passing 'undefined' to the reducer when
 * that action is received. See the answer from Dan Abramov (redux co-author) here https://stackoverflow.com/a/35641992.
 *
 * @param {ActionReducer<any>} reducer
 * @returns {ActionReducer<any>}
 */
export function resetOnLogoutReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    // Clear the entire store at logout.
    if (action.type === logoutSuccess.type || action.type === logoutError.type) {
      // Use combined reducer without 'rehydrate' exceptions, so the state is cleared with the 'undefined' state.
      return reducer(undefined, action);
    } else {
      // In any other case, use the reducer with 'rehydrate' exceptions, so reloading the page retains state.
      return reducer(state, action);
    }
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  resetOnLogoutReducer,
];
