import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../features/grid/datasource/grid.resource';
import { AuditLogServiceService } from './audit-log-service.service';
import { Observable } from 'rxjs';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import { map } from 'rxjs/operators';
import { AuditInfo } from '@geneious/nucleus-api-client';
import { DateRange } from './datepicker/datepicker.component';
import { addSpacingToCapitalizedWords, formatDateForTables } from '../../shared/string-util';
import { DateTransformer } from './DateTransformer';

export class AuditLogDataSource implements IGridResource {
  constructor(private activityLogService: AuditLogServiceService) {}

  query(
    params: IGetRowsRequestMinimal,
    optionalParams?: DateRange,
  ): Observable<IGridResourceResponse<any>> {
    const limit = params.endRow - params.startRow;
    const offset = params.startRow;

    const pageParameters = {
      limit: limit,
      offset: offset,
      includeTotal: true,
    };

    let auditLogResponse$ = this.activityLogService.fetchPage(optionalParams, pageParameters);

    return auditLogResponse$.pipe(
      map(
        (response): IGridResourceResponse<AuditInfo> => ({
          data: this.formatAuditLogResponse(response.data, response.metadata.page.offset),
          metadata: {
            total: response.metadata.page.total ?? null,
            limit: response.metadata.page.limit,
            offset: response.metadata.page.offset,
          },
        }),
      ),
    );
  }

  /**
   * The AuditInfoPaginatedResponse data received from Nucleus is changed before it is displayed in bx-grid.
   * - Spacing is added to eventType strings for readability.
   * - Timestamps are converted to local time and formatted as 'DD MMM YYYY, hh:mm a' for readability.
   * - IDs are added to each log, based on the row index.
   *
   * We need to add id to each audit log because BX-grid has not been designed to work with data that doesn't contain
   * unique ID's. There isn't really a unique part of an audit log message (even the timestamps are
   * often the same for events that occurred as part of a single transaction). So we just set the id
   * to the row index.
   */
  private formatAuditLogResponse(auditLogs: AuditInfo[], startingId: number) {
    return auditLogs.map((auditLog) => {
      if (auditLog.eventType) {
        auditLog.eventType = addSpacingToCapitalizedWords(auditLog.eventType);
      }
      if (auditLog.timestamp) {
        auditLog.timestamp = DateTransformer.fromNucleusTimestamp(
          auditLog.timestamp,
        ).asBXTableDateString();
      }
      return {
        ...auditLog,
        id: startingId++,
      };
    });
  }
}
