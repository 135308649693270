import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { VersionEnum } from '@geneious/nucleus-api-client';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { filter, map, startWith, switchMap, take } from 'rxjs';
import { HELP_CENTER_URL } from 'src/app/app.constants';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { SelectionStateV2 } from 'src/app/features/grid/grid.component';
import { SelectComponent } from 'src/app/shared/select/select.component';
import { CursorDocumentQuery } from 'src/nucleus/services/documentService/document-service.v1.http';
import { DocumentTableType } from 'src/nucleus/services/documentService/document-table-type';
import { SelectionOptionsV1 } from 'src/nucleus/services/models/jobParameters.model';
import {
  LumaSequenceFormat,
  RegisterSequencesLumaJobConfig,
  RegisterSequencesLumaOptions,
} from 'src/nucleus/services/models/registerSequencesLuma.model';
import { PipelineDialogData } from '..';
import { CardComponent } from '../../../shared/card/card.component';
import { selectUserIsOrgAdmin } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { LumaAPIService, RegistrationTableRow } from '../../luma/luma-api-service';
import { LumaConfig } from '../../luma/luma-config/luma-config.model';
import { SelectOption } from '../../models/ui/select-option.model';
import { selectLumaConfig } from '../../organization-settings/organization-settings.selectors';
import { PIPELINE_DIALOG_DATA } from '../pipeline-dialog-v2/pipeline-dialog-v2';

const LoadingStatus = {
  LOADING: 'loading',
  ERROR: 'error',
  COMPLETE: 'complete',
} as const;
type LoadingStatus = (typeof LoadingStatus)[keyof typeof LoadingStatus];

/**
 * JobDialogContent for registering sequences in Luma.
 */
@Component({
  selector: 'bx-register-sequences-luma',
  templateUrl: './register-sequences-luma.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, ReactiveFormsModule, CardComponent, SelectComponent],
})
export class RegisterSequencesLumaComponent extends JobDialogContent implements RunnableJobDialog {
  readonly title = 'Send Sequences to Luma';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup({
    setName: new BxFormControl<string>(undefined),
    format: new BxFormControl<LumaSequenceFormat>(undefined, Validators.required),
    project: new FormControl<string | undefined>(undefined, Validators.required),
    target: new FormControl<string | undefined>(undefined, Validators.required),
  });
  /** Template-bound variables for the banner text at the top of the dialog */
  readonly banner = {
    table: this.dialogData.otherVariables.table.displayName,
    numRows: this.dialogData.otherVariables.table.selection.totalSelected,
  };
  readonly helpCenterUrl = HELP_CENTER_URL;
  readonly formatOptions = Object.entries(LumaSequenceFormat).map(
    ([_, value]) => new SelectOption(value, value),
  );

  readonly projects: Signal<{ rows: RegistrationTableRow[]; status: LoadingStatus }>;
  readonly projectsStatus: Signal<LoadingStatus>;
  readonly projectsOptions: Signal<SelectOption[]>;

  readonly targets: Signal<{ rows: RegistrationTableRow[]; status: LoadingStatus }>;
  readonly targetsStatus: Signal<LoadingStatus>;
  readonly targetsOptions: Signal<SelectOption[]>;

  readonly isLumaConfigValid: Signal<boolean>;
  readonly userCanConfigureLuma: Signal<boolean>;
  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private readonly dialogData: PipelineDialogData<RegisterSequencesLumaDialogData>,
    private readonly store: Store<AppState>,
    private readonly lumaApiService: LumaAPIService,
  ) {
    super('register-sequences-luma', PipelineFormID.REGISTER_SEQUENCES_LUMA);
    this.formDefaults = this.form.getRawValue();

    this.isLumaConfigValid = toSignal(
      this.store.select(selectLumaConfig).pipe(map((config) => this.lumaConfigIsValid(config))),
    );
    effect(() => (this.isLumaConfigValid() ? this.form.enable() : this.form.disable()));

    this.userCanConfigureLuma = toSignal(this.store.select(selectUserIsOrgAdmin).pipe(take(1)), {
      initialValue: false,
    });

    this.projects = toSignal(this.fetchRegistrationEntities('Projects'));
    this.projectsOptions = computed(() =>
      this.projects().rows.map(
        (project) => new SelectOption(project.name, project.registration_id),
      ),
    );
    this.projectsStatus = computed(() => this.projects().status);

    this.targets = toSignal(this.fetchRegistrationEntities('Targets'));
    this.targetsOptions = computed(() =>
      this.targets().rows.map((target) => new SelectOption(target.name, target.registration_id)),
    );
    this.targetsStatus = computed(() => this.targets().status);
  }

  private lumaConfigIsValid(config: Partial<LumaConfig>): config is LumaConfig {
    return !!config.lumaURL && !!config.lumaAPIKey && !!config.lumaRegAppID;
  }

  private fetchRegistrationEntities(tableName: 'Projects' | 'Targets') {
    return this.store.select(selectLumaConfig).pipe(
      take(1),
      filter(this.lumaConfigIsValid),
      switchMap((config) =>
        this.lumaApiService.submitSqlQueryToSelectFromRegistrationTable(
          tableName,
          { applicationId: config.lumaRegAppID },
          config,
        ),
      ),
      map((response) => {
        if ('error' in response) {
          Sentry.captureEvent(response);
          return { status: LoadingStatus.ERROR, rows: [] as RegistrationTableRow[] };
        }
        return { status: LoadingStatus.COMPLETE, rows: response };
      }),
      startWith({ status: LoadingStatus.LOADING, rows: [] }),
    );
  }

  run(): RegisterSequencesLumaJobConfig {
    const { table, fileSelection } = this.dialogData.otherVariables;
    const options: RegisterSequencesLumaOptions = {
      tableName: table.name,
      tableQuery: table.query,
      selection: {
        ids: table.selection.ids,
        selectAll: table.selection.selectAll,
      },
      setName: this.form.controls.setName.value,
      format: this.form.controls.format.value,
      projectId: this.form.controls.project.value,
      targetId: this.form.controls.target.value,
    };

    return {
      pipeline: {
        name: 'register-sequences-luma',
        version: VersionEnum.Latest,
      },
      parameters: {
        options,
        selection: fileSelection,
      },
    };
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}

export interface RegisterSequencesLumaDialogData {
  table: {
    name: string;
    displayName: string;
    type: DocumentTableType;
    query: CursorDocumentQuery;
    selection: SelectionStateV2;
  };
  fileSelection: SelectionOptionsV1;
}
