import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTimeString, DateTransformer } from '../DateTransformer';

@Component({
  selector: 'bx-datepicker',
  standalone: true,
  imports: [NgbInputDatepicker, ReactiveFormsModule],
  templateUrl: './datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit {
  @Input() startingDateRange: DateRange;
  @Output() newDateEmitter: EventEmitter<DateRange> = new EventEmitter();

  dateRangeControl: FormGroup;
  startDate: DateTimeString; // Should be a date-time form in the format YYYY-MM-DDThh:mm'
  endDate: DateTimeString; // Should be a date-time form in the format YYYY-MM-DDThh:mm'
  max: DateTimeString;

  // noinspection JSUnusedGlobalSymbols
  ngOnInit(): void {
    this.endDate = this.startingDateRange.end.asDateTimeString();
    this.startDate = this.startingDateRange.start.asDateTimeString();
    this.max = this.endDate;

    this.dateRangeControl = this.formBuilder.group({
      startDate: new FormControl(this.startDate),
      endDate: new FormControl(this.endDate),
    });
    this.dateRangeControl.valueChanges.subscribe((dates) => {
      this.startDate = dates.startDate;
      this.endDate = dates.endDate;
    });

    this.passDetails();
  }

  constructor(private formBuilder: FormBuilder) {}

  // selected dates as event emitter for audit-log component
  passDetails() {
    this.newDateEmitter.next({
      start: DateTransformer.fromDateTimeString(this.startDate),
      end: DateTransformer.fromDateTimeString(this.endDate),
    });
  }
}

export type DateRange = {
  start: DateTransformer;
  end: DateTransformer;
};
