import { Injectable } from '@angular/core';
import User from '../user-editor/user.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EditorState } from '../user-editor/editor-state.enum';
import {
  cancelCreation,
  disableUser,
  forgetUser,
  setEditorState,
  updateUser,
} from '../user-editor/user-editor.actions';
import * as UserEditor from '../user-editor/user-editor.reducer';
import { AppState } from '../core.store';

@Injectable({
  providedIn: 'root',
})
export class UsersStoreService {
  constructor(private store: Store<AppState>) {}

  getStore(): Observable<UserEditor.State> {
    return this.store.select('userEditorReducer');
  }

  /**
   * Sets the editor state so that the template knows which elements to display and hide
   * @param state the current state of the editor
   */
  editorState(state: EditorState) {
    this.store.dispatch(setEditorState({ state }));
  }

  disableUser(userID: string) {
    this.store.dispatch(disableUser({ userID }));
  }

  cancelCreation() {
    this.store.dispatch(cancelCreation());
  }

  updateUser(user: User) {
    this.store.dispatch(updateUser({ user }));
  }

  resetUser() {
    this.store.dispatch(forgetUser());
  }
}
