import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, Route, Routes } from '@angular/router';
import { UsersComponent } from '../users/users.component';
import { NameSchemesManagerComponent } from '../name-schemes/name-schemes-manager/name-schemes-manager.component';
import { UploadsTableComponent } from '../upload/uploads-table/uploads-table.component';
import { SecureComponent } from '../secure/secure.component';
import { PrimersRootFolderDetailComponent } from '../folder-detail/primers-root-folder-detail/primers-root-folder-detail.component';
import { BlastFolderDetailComponent } from '../folder-detail/blast-folder-detail/blast-folder-detail.component';
import { BlastRootFolderDetailComponent } from '../folder-detail/blast-root-folder-detail/blast-root-folder-detail.component';
import { ReferenceRootFolderDetailComponent } from '../folder-detail/reference-root-folder-detail/reference-root-folder-detail.component';
import { PrimerSetsFolderDetailComponent } from '../folder-detail/primer-sets-folder-detail/primer-sets-folder-detail.component';
import { WorkspaceRootFolderDetailComponent } from '../folder-detail/workspace-root-folder-detail/workspace-root-folder-detail.component';
import { AdminAuthGuard } from '../auth/adminAuth.guard';
import { NucleusAdminAuthGuard } from '../auth/nucleusAdminAuth.guard';
import { UserEditorComponent } from '../user-editor/user-editor.component';
import { UserAccountPageComponent } from '../user-account-page/user-account-page.component';
import { ExternalRedirectComponent } from '../external/external-redirect/external-redirect.component';
import { HELP_CENTER_URL } from '../../app.constants';
import { SearchResultsComponent } from '../search/search-results/search-results.component';
import { NameSchemesCreatorComponent } from '../name-schemes/name-schemes-creator/name-schemes-creator.component';
import { ViewerPageComponent } from '../viewer-page/viewer-page.component';
import { JobsTableComponent } from '../jobs/jobs-table/jobs-table.component';
import { LabelsComponent } from '../label/labels/labels.component';
import { LabelEditorComponent } from '../label/label-editor/label-editor.component';
import { SecureResolver } from '../resolvers/secure.resolver';
import { GeneiousGuard } from '../external/login/geneious.guard';
import { AdvancedReportGuard } from '../auth/advancedReport.guard';
import { UserGroupsComponent } from '../user-groups/user-groups.component';
import { UserGroupsFormComponent } from '../user-groups/user-groups-form/user-groups-form.component';
import { JobDetailsPageComponent } from '../jobs/job-details/job-details-page/job-details-page.component';
import { ReportComponent } from '../../features/report/report.component';
import { MasterDatabaseRootFolderDetailComponent } from '../folder-detail/master-database-root-folder-detail/master-database-root-folder-detail.component';
import { AuthGuard } from '../auth/auth.guard';
import { publicRoutes } from './public.routing';
import { UsersApiKeysManagerComponent } from '../api-keys/users-api-keys-manager/users-api-keys-manager.component';
import { ApiKeyCreatorComponent } from '../api-keys/api-key-creator/api-key-creator.component';
import { PasswordChangerViewComponent } from '../password-changer/password-changer.component';
import { AccessCheckGuard } from '../../shared/access-check/access-check.guard';
import { GettingStartedPageComponent } from '../getting-started-page/getting-started-page.component';
import { HomeRedirectGuard } from '../navigation/home-redirect.guard';
import { BxRoute, GETTING_STARTED_URL, WORKSPACE_URL } from './app.routing.model';
import { AccessCondition } from 'src/app/shared/access-check/access-check-condition.model';
import { FilesFolderDetailComponent } from '../folder-detail/files-folder-detail/files-folder-detail.component';
import { BioregisterConfigComponent } from '../bioregister-config/bioregister-config.component';
import { LumaConfigComponent } from '../luma/luma-config/luma-config.component';
import { AuditLogComponent } from '../audit-log/audit-log/audit-log.component';
import { EmailChangerComponent } from '../email-changer/email-changer.component';

const NOT_FREE_ORG: AccessCondition = { orgProfiles: ['standard', 'unrestricted', 'associate'] };

const inAppRoutes: BxRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    children: [], // Required for compilation: https://github.com/angular/angular/issues/13373
    canActivate: [HomeRedirectGuard], // Determines actual target route
  },
  {
    path: GETTING_STARTED_URL,
    component: GettingStartedPageComponent,
    canActivate: [AccessCheckGuard],
    data: {
      selectedID: 'Getting Started',
      nonFolder: true,
    },
  },
  {
    path: WORKSPACE_URL,
    component: WorkspaceRootFolderDetailComponent,
  },
  {
    path: 'folders/:folderID/files',
    pathMatch: 'full',
    component: FilesFolderDetailComponent,
  },
  {
    path: 'folders/:folderID',
    redirectTo: 'folders/:folderID/files',
    pathMatch: 'full',
  },
  {
    // TODO Id not strictly necessary, but useful for folder-tree selection. Remove or provide an alias if desirable.
    path: 'reference-databases/:folderID',
    component: ReferenceRootFolderDetailComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'database-root/:folderID',
    component: BlastRootFolderDetailComponent,
    pathMatch: 'full',
  },
  {
    path: 'database/:folderID',
    component: BlastFolderDetailComponent,
  },
  {
    path: 'collections-root/:folderID',
    component: MasterDatabaseRootFolderDetailComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'collections-folder/:folderID',
    component: BlastFolderDetailComponent,
  },
  {
    path: 'blast-root/:folderID',
    redirectTo: '/database-root/:folderID',
    pathMatch: 'full',
  },
  {
    path: 'blast/:folderID',
    redirectTo: '/database/:folderID',
    pathMatch: 'full',
  },
  {
    path: 'primers/:folderID',
    component: PrimersRootFolderDetailComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'primer-sets/:folderID',
    component: PrimerSetsFolderDetailComponent,
    canActivate: [AccessCheckGuard],
    data: { accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'search-results',
    component: SearchResultsComponent,
    data: {},
  },
  {
    path: 'uploads',
    component: UploadsTableComponent,
    pathMatch: 'full',
    data: { selectedID: 'Uploads', nonFolder: true },
    children: [],
  },
  {
    path: 'jobs',
    component: JobsTableComponent,
    pathMatch: 'full',
    data: { selectedID: 'Jobs', nonFolder: true },
    children: [],
  },
  {
    path: 'jobs/:jobID',
    component: JobDetailsPageComponent,
    pathMatch: 'full',
    data: { selectedID: 'Jobs', nonFolder: true },
    children: [],
  },
  {
    path: 'users',
    component: UsersComponent,
    pathMatch: 'full',
    data: { selectedID: 'Users', nonFolder: true },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'audit-log',
    component: AuditLogComponent,
    pathMatch: 'full',
    data: { selectedID: 'Audit Log', nonFolder: true },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'users/api-keys',
    component: UsersApiKeysManagerComponent,
    pathMatch: 'full',
    data: { selectedID: 'Users', nonFolder: true },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'groups',
    component: UserGroupsComponent,
    pathMatch: 'full',
    data: { selectedID: 'Groups', nonFolder: true },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'groups/:groupId',
    component: UserGroupsFormComponent,
    data: { selectedID: 'Groups', nonFolder: true },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'geneious-admin',
    canActivate: [NucleusAdminAuthGuard],
    data: { selectedID: 'Organizations', nonFolder: true },
    loadChildren: () =>
      import('../../features/geneious-admin/geneious-admin-routing.module').then(
        (m) => m.GeneiousAdminRouting,
      ),
  },
  {
    path: 'users/:userID',
    component: UserEditorComponent,
    data: { selectedID: 'Users', nonFolder: true },
    canActivate: [AdminAuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'users/:userID/change-email',
    component: EmailChangerComponent,
    data: { selectedID: 'Users', nonFolder: true, parentPage: 'org-admin' },
    canActivate: [AdminAuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'name-schemes',
    component: NameSchemesManagerComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { selectedID: 'Name Schemes', nonFolder: true, accessCondition: 'nameSchemes' },
  },
  {
    path: 'bioregister',
    component: BioregisterConfigComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard, AdminAuthGuard],
    data: {
      selectedID: 'Bioregister',
      nonFolder: true,
      accessCondition: 'registerSequencesBioregister',
    },
  },
  {
    path: 'luma',
    component: LumaConfigComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard, AdminAuthGuard],
    data: { selectedID: 'Luma', nonFolder: true, accessCondition: 'lumaIntegration' },
  },
  {
    path: 'name-schemes/new',
    component: NameSchemesCreatorComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { selectedID: 'Name Schemes', nonFolder: true, accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'labels',
    component: LabelsComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { selectedID: 'Labels', nonFolder: true, accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'labels/create',
    component: LabelEditorComponent,
    pathMatch: 'full',
    canActivate: [AccessCheckGuard],
    data: { selectedID: 'Labels', nonFolder: true, accessCondition: NOT_FREE_ORG },
  },
  {
    path: 'settings',
    component: UserAccountPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'settings/create-api-key',
    component: ApiKeyCreatorComponent,
    pathMatch: 'full',
  },
  {
    path: 'settings/change-password',
    component: PasswordChangerViewComponent,
    pathMatch: 'full',
  },
  {
    path: 'settings/change-email',
    component: EmailChangerComponent,
    pathMatch: 'full',
    data: {
      parentPage: 'user-profile',
    },
  },
  {
    path: 'help',
    component: ExternalRedirectComponent,
    pathMatch: 'full',
    data: {
      redirectTo: HELP_CENTER_URL,
    },
  },
  {
    path: 'document',
    pathMatch: 'full',
    component: ViewerPageComponent,
  },
  {
    path: 'theme',
    canActivate: [AccessCheckGuard],
    data: { accessCondition: { orgProfiles: ['unrestricted'] } },
    loadChildren: () =>
      import('../../features/theme-preview/theme-preview-routing.module').then(
        (m) => m.ThemePreviewRoutingModule,
      ),
  },
];

// This must be defined in its own `const` to avoid having to export the `setRedirects` function.
// All code must finish evaluating before the routes get exported to Angular.
const oldSecureRouteRedirects: Route = {
  path: 'secure',
  redirectTo: '',
  children: setRedirects(inAppRoutes),
};

export const appRoutes: Routes = [
  ...publicRoutes,
  {
    path: 'view',
    canActivate: [AuthGuard, GeneiousGuard],
    resolve: {
      data: SecureResolver,
    },
    component: ViewerPageComponent,
  },
  {
    path: 'report',
    canActivate: [AuthGuard, GeneiousGuard],
    loadComponent: () =>
      import('../export/report/export-report.component').then((m) => m.ExportReportComponent),
  },
  {
    path: 'advanced-report',
    canActivate: [
      // AdvancedReportGuard internally calls InternalAuthGuard so it can use the 2 guards sequentially.
      AdvancedReportGuard,
    ],
    resolve: {
      data: SecureResolver,
    },
    loadComponent: () =>
      import('../../features/report/report.component').then((m) => m.ReportComponent),
  },
  {
    path: 'sequence-viewer-image-export',
    canActivate: [AuthGuard],
    resolve: {
      data: SecureResolver,
    },
    loadChildren: () =>
      import(
        '../../features/sequence-viewer-image-export/sequence-viewer-image-export.module'
      ).then((m) => m.SequenceViewerImageExportModule),
  },
  {
    path: '',
    component: SecureComponent,
    canActivate: [AuthGuard, GeneiousGuard],
    resolve: {
      data: SecureResolver,
    },
    children: inAppRoutes,
  },
  oldSecureRouteRedirects,
  // Catch all route
  // If the user is unauthenticated, then the InternalAuthGuard will redirect them back to the login page.
  // If the user is authenticated, the HomeRedirectGuard on path '' will take them home.
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

/**
 * NOTE: This function needs to be exported due to a bug in Angular's routing compiler
 * https://github.com/angular/angular/issues/22700
 *
 * Sets redirects on each route to the new root route.
 * In this case it's redirecting all '/secure' routes to the new root blank '' route.
 */
export function setRedirects(routes: Routes): Routes {
  return routes.reduce((redirectedRoutes, route) => {
    if (route.children) {
      return redirectedRoutes.concat(setRedirects(route.children));
    }
    return redirectedRoutes.concat([
      {
        path: route.path,
        pathMatch: route.pathMatch,
        redirectTo: route.path,
      },
    ]);
  }, []);
}

/**
 * NOTE: This custom route reuse is present to make Angular router cache route so that we don't have to re-render
 * when user navigate back & forth.
 */
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (
      super.shouldReuseRoute(future, curr) &&
      future.routeConfig?.component !== FilesFolderDetailComponent
    );
  }
}
