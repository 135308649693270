import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HELP_CENTER_URL } from '../../../../app.constants';

@Component({
  selector: 'bx-reset-request-failure',
  templateUrl: './request-failure.component.html',
  standalone: true,
  imports: [RouterLink],
})
export class RequestFailureComponent {
  readonly HELP_CENTER_URL = HELP_CENTER_URL;
}
