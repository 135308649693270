import { createAction, props } from '@ngrx/store';
import { EditorState } from './editor-state.enum';
import User from './user.model';

export const ACTION_PREFIX = '[user-editor]';

// payload is the state it which the editor is in
// e.g.,
//  - if creating a user then it would be CREATING
//  - if editing a user then it would be EDITING
// etc.
export const setEditorState = createAction(
  `${ACTION_PREFIX} Set editor state`,
  props<{ state: EditorState }>(),
);
export const disableUser = createAction(
  `${ACTION_PREFIX} Disable user`,
  props<{ userID: string }>(),
);
export const updateUser = createAction(`${ACTION_PREFIX} Update user`, props<{ user: User }>());
export const forgetUser = createAction(`${ACTION_PREFIX} Forget user`);
export const cancelCreation = createAction(`${ACTION_PREFIX} Cancel creation`);
