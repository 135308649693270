import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap, take } from 'rxjs';
import { AppState } from '../core.store';
import { selectLumaConfig } from '../organization-settings/organization-settings.selectors';
import { LumaConnectionConfig } from './luma-config/luma-config.model';

// The Luma API schema can be inspected here:
// https://infragistics-application.aqua.cluster-1.dev.luma.cloud.dotmatics.com/data-core/apps/82a135b8-727c-4382-a183-90881b4d36db/utilities/api-explorer
// Most object types have additional fields that we are not currently using

/** Schema for the `applicationVersion` object type. */
export type LumaApplicationVersion = {
  name: string;
  description: string;
  applicationId: string;
  applicationVersionId: string;
  state: 'DRAFT' | 'COMMITTED';
  enabled: boolean;
  registrationEnabled: boolean;
  isLatestAppVersion: boolean;
  version: {
    version: string;
  };
};
/**
 * Fields to include in the GraphQL query string for `applicationVersion` objects.
 * Must remain in sync with the properties in {@link LumaApplicationVersion}.
 */
const applicationVersionFields = `
      name
      description
      applicationId
      applicationVersionId
      state
      enabled
      registrationEnabled
      isLatestAppVersion
      version {
        version
      }
`;

/** Schema for the `paginationInfo` object type. */
export type PaginationInfo = {
  totalCount: number;
};
/**
 * Fields to include in the GraphQL query string for `paginationInfo` objects.
 * Must remain in sync with the properties in {@link PaginationInfo}.
 */
const paginationInfoFields = `
      totalCount
`;

export type SqlQueryResponse = {
  /** Response data, or null if the query errored or timed out. */
  result: {
    count: number;
    /** SQL row data as stringified JSON objects */
    data: string[];
  } | null;
  writeJob: {
    failureMessage: string | null;
    durationSeconds: number;
    status: string;
    id: string;
  };
};

/**
 * The data format for Project and Target entity tables in the Registration App.
 * Returned as a JSON string from SQL queries.
 */
export type RegistrationTableRow = {
  first_submission_id: string;
  name: string;
  registration_date: string;
  registration_id: string;
  'Repo Name': string;
  primary_material_id: string;
};

type QueryResponse<T extends Record<string, unknown>> = Partial<T> & { errors?: LumaAPIError[] };
type LumaAPIError = {
  message: string;
  locations: { line: number; column: number }[];
};
/**
 * GraphQL responses contain the fields that were specified in the query. This interface models the
 * data type of each field that could be returned in the response. A query's response type can be
 * easily defined by picking a property from this interface for each field it queries.
 */
export type LumaResponseFields = {
  latestApplicationVersionsWithDrafts: QueryResponse<{
    data: LumaApplicationVersion[];
    paginationInfo: PaginationInfo;
  }>;
  applicationVersion: LumaApplicationVersion;
  addSqlQuery: SqlQueryResponse;
};

export type PageVariables = { after: number; limit: number };
export type LumaVariables = {
  applicationId: string;
  applicationVersionId: string;
  dataSourceId: string;
  pagination: PageVariables;
  registrationEnabled: boolean;
  code: string;
  waitForCompletionTimeout: number;
};

// Queries are defined here because they are constants and are difficult to read when nested in a class

export const latestApplicationVersionsWithDraftsQuery = `query LatestApplicationVersionsWithDrafts(
  $registrationEnabled: Boolean
  $pagination: paginationInputType
) {
  latestApplicationVersionsWithDrafts(
    registrationEnabled: $registrationEnabled
    pagination: $pagination
  ) {
    data {${applicationVersionFields}}
    paginationInfo {${paginationInfoFields}}
  }
}`;

export const addSqlQueryMutation = `mutation AddSqlQuery(
  $code: String!
  $applicationId: applicationId!
  $waitForCompletionTimeout: Int!
) {
  addSqlQuery(
    code: $code
    applicationId: $applicationId
    waitForCompletionTimeout: $waitForCompletionTimeout
  ) {
    result {
      data
      count
    }
    writeJob {
      id
      status
      durationSeconds
      failureMessage
    }
  }
}`;

/**
 * A service that makes HTTP requests to Luma. For the most part, the API is a REST API, but the
 * datacore query endpoint accepts GraphQL payloads.
 */
@Injectable({
  providedIn: 'root',
})
export class LumaAPIService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
  ) {}

  /**
   * Sends a query for the `latestApplicationVersionsWithDrafts` field and extracts the
   * relevant data from the response.
   *
   * @param variables optional variables for filtering and pagination
   * @param config the Luma connection configuration. If undefined, the config in the
   *    store will be used.
   * @returns a list of the latest application versions
   * @see {@link latestApplicationVersionsWithDraftsQuery}
   */
  queryLatestApplicationVersionsWithDrafts(
    variables: { registrationEnabled?: boolean; pagination?: PageVariables },
    config?: LumaConnectionConfig,
  ): Observable<LumaApplicationVersion[]> {
    return this.postDatacoreRequest<'latestApplicationVersionsWithDrafts'>(
      latestApplicationVersionsWithDraftsQuery,
      variables,
      config,
    ).pipe(map((res) => res.data.latestApplicationVersionsWithDrafts.data));
  }

  submitSqlQueryToSelectFromRegistrationTable(
    tableName: 'Projects' | 'Targets',
    variables: { applicationId: string },
    config?: LumaConnectionConfig,
  ): Observable<RegistrationTableRow[] | { error: string; message: string }> {
    const waitForCompletionTimeout = 15;
    const code = `SELECT * FROM ${tableName}`;
    return this.mutationAddSqlQuery({ ...variables, waitForCompletionTimeout, code }, config).pipe(
      map((addSqlQuery) => {
        if (addSqlQuery.result != null) {
          return addSqlQuery.result.data.map((row) => JSON.parse(row) as RegistrationTableRow);
        }
        if (addSqlQuery.writeJob.failureMessage != null) {
          return {
            error: 'JobFailed',
            message: `SQL query "${code}" failed with message: ${addSqlQuery.writeJob.failureMessage}`,
          };
        }
        return {
          error: 'JobTimeout',
          message: `SQL query job ${addSqlQuery.writeJob.id} did not complete in ${waitForCompletionTimeout} seconds. Job status: ${addSqlQuery.writeJob.status}`,
        };
      }),
      catchError((err) =>
        of({
          error: 'RequestFailed',
          message: JSON.stringify(err),
        }),
      ),
    );
  }

  /**
   * Sends an addSqlQuery GraphQL mutation to Luma. These are read-only SQL
   * queries that get executed as a Spark job. If the query times out, the
   * result field will be null, and the writeJob field will contain the job's
   * status. The job ID can then be used to retrieve results later.
   *
   * @param variables the SQL query, the target app, and the number of seconds
   *    to wait for the job before responding
   * @param config the Luma connection configuration. If undefined, the config in the
   *    store will be used.
   * @returns the response from Luma
   */
  private mutationAddSqlQuery(
    variables: { code: string; applicationId: string; waitForCompletionTimeout: number },
    config?: LumaConnectionConfig,
  ): Observable<SqlQueryResponse> {
    return this.postDatacoreRequest<'addSqlQuery'>(addSqlQueryMutation, variables, config).pipe(
      map((res) => res.data.addSqlQuery),
    );
  }

  /**
   * Sends a GraphQL operation to the datacore-api endpoint.
   *
   * @param query string containing the GraphQL query
   * @param variables GraphQL query variables
   * @param config the Luma connection config
   * @returns the query response
   */
  private postDatacoreRequest<T extends keyof LumaResponseFields>(
    query: string,
    variables: Partial<LumaVariables>,
    config?: LumaConnectionConfig,
  ): Observable<QueryResponse<{ data: Pick<LumaResponseFields, T> }>> {
    const config$: Observable<LumaConnectionConfig> = config
      ? of(config)
      : this.selectLumaConfigProperties('lumaURL', 'lumaAPIKey');

    return config$.pipe(
      switchMap((config) =>
        this.http.post<{ data: Pick<LumaResponseFields, T> }>(
          `${config.lumaURL}/api/datacore-api/v1/graphql`,
          { query, variables },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${config.lumaAPIKey}`,
            },
          },
        ),
      ),
    );
  }

  /**
   * Plucks properties from the Luma config in the store. Throws an error if any of
   * the properties has an invalid value.
   *
   * @param properties to pluck from the config
   * @returns an observable containing the filtered config object
   */
  private selectLumaConfigProperties<K extends keyof LumaConnectionConfig>(
    ...properties: K[]
  ): Observable<Pick<LumaConnectionConfig, K>> {
    return this.store.select(selectLumaConfig).pipe(
      take(1),
      map((config) => {
        const filteredConfig: Partial<LumaConnectionConfig> = {};
        for (const prop of properties) {
          const value = config[prop];
          if (typeof value !== 'string' || value.length === 0) {
            throw new Error(
              `Invalid value for configuration property ${prop}: ${JSON.stringify(value)}`,
            );
          }
          filteredConfig[prop] = value;
        }
        return filteredConfig as Pick<LumaConnectionConfig, K>;
      }),
    );
  }
}
