import { createAction, props, union } from '@ngrx/store';
import { Search } from './search.model';

const prefix = '[Search]';

export const saveSearch = createAction(`${prefix} Save search`, props<{ query: Search.Query }>());
export const saveReferrer = createAction(
  `${prefix} Save search`,
  props<{ referrerPath: string }>(),
);
