export const CLASSIFICATION_OPTIONS: { readonly label: string; readonly value: string }[] = [
  {
    label: 'Common Identifier',
    value: 'id',
  },
  {
    label: 'Chain',
    value: 'chain',
  },
  {
    label: 'Direction',
    value: 'direction',
  },
];
