import {
  authenticate,
  authenticateError,
  authenticationSuccess,
  cancelMfaInProgress,
  logout,
  logoutError,
  logoutSuccess,
  requestMfa,
  submitForgotPasswordRequest,
  submitForgotPasswordRequestFailure,
  submitForgotPasswordRequestSuccess,
  verifyAuthentication,
  verifyAuthenticationFailure,
  verifyAuthenticationSuccess,
} from './auth.actions';
import { CurrentUserInfo } from '@geneious/nucleus-api-client';
import { OrganizationDetails } from '@geneious/nucleus-api-client/model/organization-details';
import { createReducer, on } from '@ngrx/store';

export interface AuthState {
  authenticated: boolean;
  error?: string;
  errorCode?: number;
  loggingIn: boolean;
  verifying?: boolean;
  submittingForgotPassword: boolean;
  userInfo?: CurrentUserInfo;
  organizationInfo?: OrganizationDetails;
  jwt?: string;
  mfaRequired?: boolean;
}

export const initialState: AuthState = {
  authenticated: false,
  loggingIn: false,
  verifying: false,
  submittingForgotPassword: false,
  mfaRequired: false,
};

export type LoginErrorState = Pick<AuthState, 'error' | 'errorCode' | 'loggingIn'>;

export const authReducer = createReducer(
  initialState,
  on(authenticate, (state) => ({
    ...state,
    loggingIn: true,
    error: null,
    errorCode: null,
    authenticated: false,
    verifying: false,
  })),
  on(authenticationSuccess, (state, { userInfo, organizationInfo }) => ({
    ...state,
    userInfo,
    organizationInfo,
    loggingIn: false,
    authenticated: true,
    verifying: false,
  })),
  on(authenticateError, (state, { error, errorCode }) => ({
    ...state,
    loggingIn: false,
    error: error,
    errorCode: errorCode,
    authenticated: false,
    verifying: false,
  })),
  on(logout, (state) => ({
    ...state,
    ...{ loggingIn: false },
  })),
  on(requestMfa, (state) => ({
    ...state,
    mfaRequired: true,
    loggingIn: false,
  })),
  on(cancelMfaInProgress, (state) => ({
    ...state,
    mfaRequired: false,
    error: null,
    errorCode: null,
  })),
  on(logoutSuccess, (state) => ({
    ...state,
    loggingIn: false,
    authenticated: false,
    verifying: false,
  })),
  on(logoutError, (state) => ({
    ...state,
    ...{ loggingIn: false },
  })),
  on(verifyAuthentication, (state) => ({
    ...state,
    ...{ verifying: true },
  })),
  on(verifyAuthenticationSuccess, (state, { userInfo, jwt, organizationInfo }) => ({
    ...state,
    ...{
      authenticated: true,
      verifying: false,
      features: {},
      userInfo,
      organizationInfo,
      jwt,
    },
  })),
  on(verifyAuthenticationFailure, (state) => ({
    ...state,
    ...{
      authenticated: false,
      verifying: false,
      features: {},
    },
  })),
  on(submitForgotPasswordRequest, () => ({
    authenticated: false,
    mfaRequired: false,
    verifying: false,
    loggingIn: false,
    submittingForgotPassword: true,
  })),
  on(submitForgotPasswordRequestSuccess, () => ({
    authenticated: false,
    mfaRequired: false,
    verifying: false,
    loggingIn: false,
    submittingForgotPassword: false,
  })),
  on(submitForgotPasswordRequestFailure, () => ({
    authenticated: false,
    mfaRequired: false,
    verifying: false,
    loggingIn: false,
    submittingForgotPassword: false,
  })),
);
