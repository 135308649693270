import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, NgForOf, NgOptimizedImage } from '@angular/common';
import { UploadButtonDirective } from '../../upload/upload-button.directive';
import { MatRadioModule } from '@angular/material/radio';
import { SearchBarComponent } from '../../search/search-bar/search-bar.component';
import { DocumentSummaryCardComponent } from '../../document-info/document-summary-card/document-summary-card.component';
import { CardComponent } from '../../../shared/card/card.component';
import { GridComponent, SelectionState } from '../../../features/grid/grid.component';
import { ColDef, Column, GridOptions } from '@ag-grid-community/core';
import { DocumentTablePickerComponent } from '../../document-table-service/document-table-picker/document-table-picker.component';
import { BehaviorSubject } from 'rxjs';
import { AuditLogServiceService } from '../audit-log-service.service';
import { addSpacingToCapitalizedWords, formatDateForTables } from '../../../shared/string-util';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { GridSidebarComponent } from '../../../features/grid/grid-sidebar/grid-sidebar.component';
import { ClientGridV2Component } from '../../../features/grid/client-grid-v2/client-grid-v2.component';
import { GridSelectionStateDirective } from '../../grid-selection-state/grid-selection-state.directive';
import { ColumnManagementSidebarItemComponent } from '../../../features/grid/column-management-sidebar-item/column-management-sidebar-item.component';
import {
  NgbDatepicker,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { AuditInfo } from '@geneious/nucleus-api-client';
import { MatIconModule } from '@angular/material/icon';
import { AngularSplitModule } from 'angular-split';
import LogColDefinitions from '../LogColDefinitions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuditLogDataSource } from '../AuditLogDataSource';
import { DatepickerComponent, DateRange } from '../datepicker/datepicker.component';
import { DateTimeString, DateTransformer } from '../DateTransformer';
import { DownloadBlobService } from '../../download-blob.service';
import { ViewersStateDirective } from '../../viewers-state/viewers-state.directive';
import { AuditInfoPaginatedResponse } from '@geneious/nucleus-api-client/model/audit-info-paginated-response';

@Component({
  selector: 'bx-audit-log',
  standalone: true,
  imports: [
    MatButtonToggleModule,
    MatButtonModule,
    MatCardModule,
    NgOptimizedImage,
    UploadButtonDirective,
    MatRadioModule,
    SearchBarComponent,
    NgForOf,
    DocumentSummaryCardComponent,
    CardComponent,
    AsyncPipe,
    GridComponent,
    DocumentTablePickerComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    SettingsBreadcrumbComponent,
    GridSidebarComponent,
    ClientGridV2Component,
    GridSelectionStateDirective,
    ColumnManagementSidebarItemComponent,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdown,
    NgbDropdownToggle,
    MatIconModule,
    AngularSplitModule,
    FormsModule,
    NgbInputDatepicker,
    ReactiveFormsModule,
    NgbDatepicker,
    DatepickerComponent,
    ViewersStateDirective,
  ],
  templateUrl: './audit-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogComponent implements OnDestroy {
  tableColumns: Array<Column>; // define table columns
  bumpoutEntry$: BehaviorSubject<AuditInfo>; // this is for selected information to be shown beneath table

  startDate: DateTimeString;
  endDate: DateTimeString;

  protected readonly LogColDefinitions = LogColDefinitions;
  protected readonly addSpacingToCapitalizedWords = addSpacingToCapitalizedWords;
  protected readonly formatDateForTables = formatDateForTables;

  protected readonly gridOptions: GridOptions;
  protected datasource = new AuditLogDataSource(this.activityLogService);

  protected datasourceParams$: BehaviorSubject<DateRange> = new BehaviorSubject({
    start: DateTransformer.fromDate(new Date()).toOneWeekPrior(),
    end: DateTransformer.fromDate(new Date()),
  });

  constructor(private activityLogService: AuditLogServiceService) {
    this.tableColumns = this.createColumns(LogColDefinitions);
    this.gridOptions = {
      rowSelection: 'single',
      enableRangeSelection: false,
    };

    this.bumpoutEntry$ = new BehaviorSubject({
      timestamp: null,
      eventType: null,
      description: null,
    });
  }

  // sets up the columns bx-grid uses for this table
  createColumns(definitions: ColDef[]) {
    let colList = new Array<Column>();
    for (let i = 0; i < definitions.length; i++) {
      let col = new Column(definitions[i], null, definitions[i].headerName, true);
      colList.push(col);
    }
    return colList;
  }

  //exports to file
  handleExport(fileType: '.json' | '.csv') {
    const auditLogResponse$ = this.activityLogService.exportPage(this.datasourceParams$.getValue());
    const { start, end } = this.datasourceParams$.getValue();
    auditLogResponse$.subscribe((res) => {
      if (fileType == '.csv') {
        DownloadBlobService.download(
          `Logs_${start.asFileName()}_${end.asFileName()}${fileType}`,
          this.formatForCSV(res),
        );
      } else if (fileType == '.json') {
        DownloadBlobService.download(
          `Logs_${start.asFileName()}_${end.asFileName()}${fileType}`,
          this.formatForJson(res),
        );
      }
    });
  }

  //link to help article
  openHelpWindow(e: Event) {
    e.preventDefault();
    window.open('https://help.geneious.com/hc/en-us/articles/32460552839700');
  }

  // displays current selection
  handleSelection(selection: SelectionState) {
    this.bumpoutEntry$.next(selection.selectedRows[0] as AuditInfo);
  }

  // update range when date selection resubmitted
  dateChanged(dateRange: DateRange) {
    this.datasourceParams$.next(dateRange);
  }

  // format data for csv
  formatForCSV(res: AuditInfoPaginatedResponse) {
    // referenced StackOverflow /questions/8847766
    const replacer = (_: any, value: any) => (value === null ? '' : value);
    let header: string[] = [
      'timestamp',
      'eventType',
      'description',
      'actingUserEmail',
      'affectedFolderID',
      'affectedDocumentID',
      'actingUserID',
      'actingUserIP',
    ];
    let body = res.data.map((row: any) =>
      header
        .map((fieldName) => {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(','),
    );
    body.unshift(header.join(','));
    return body.join('\r\n');
  }

  // format data for json
  formatForJson(res: AuditInfoPaginatedResponse) {
    return JSON.stringify(res.data, null, 2);
  }

  // cleanup
  ngOnDestroy(): void {
    this.bumpoutEntry$.complete();
    this.datasourceParams$.complete();
  }
}
