import { Routes } from '@angular/router';
import { GeneiousGuard } from '../external/login/geneious.guard';
import { AccountSetupComponent } from '../external/account-setup/account-setup.component';
import { AccountCompletedComponent } from '../external/account-setup/complete/account-completed.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { ExternalAuthGuard } from '../auth/externalAuth.guard';
import { ForgotPasswordComponent } from '../external/reset-password/forgot-password/forgot-password.component';
import { RequestSentComponent } from '../external/reset-password/sent/request-sent.component';
import { ChangePasswordComponent } from '../external/reset-password/change/change-password.component';
import { ChangePasswordGuard } from '../external/reset-password/change/change-password.guard';
import { ResetPasswordCompleteComponent } from '../external/reset-password/complete/reset-password-complete.component';
import { PublicPageComponent } from '../external/public-page/public-page.component';
import { SSORedirectResolver } from '../resolvers/sso-redirect.resolver';
import { AccountSetupGuard } from '../external/account-setup/account-setup.guard';
import { LoginOrSignupWrapperComponent } from '../external/login-or-signup-wrapper/login-or-signup-wrapper.component';
import { SignupWithEmailComponent } from '../external/signup/signup-with-email/signup-with-email.component';
import { SignupCompleteComponent } from '../external/signup/signup-complete/signup-complete.component';
import { EmailChangeConfirmationComponent } from '../external/email-change-confirmation/email-change-confirmation.component';
import { EmailChangeConfirmationGuard } from '../external/email-change-confirmation/email-change-confirmation.guard';
import { RequestFailureComponent } from '../external/reset-password/failure/request-failure.component';

export const publicRoutes: Routes = [
  {
    path: 'login',
    component: LoginOrSignupWrapperComponent,
    pathMatch: 'full',
    canActivate: [GeneiousGuard],
    resolve: {
      ssoError: SSORedirectResolver,
    },
  },
  {
    path: 'account-setup',
    component: PublicPageComponent,
    children: [
      {
        path: '',
        component: AccountSetupComponent,
        pathMatch: 'full',
        canActivate: [AccountSetupGuard],
      },
      {
        path: 'complete',
        component: AccountCompletedComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'sign-up',
    component: SignupWithEmailComponent,
    pathMatch: 'full',
  },
  {
    path: 'sign-up/complete',
    component: PublicPageComponent,
    canActivate: [ExternalAuthGuard],
    children: [
      {
        path: '',
        component: SignupCompleteComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'reset-password',
    component: PublicPageComponent,
    canActivate: [ExternalAuthGuard],
    children: [
      {
        path: 'request',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
      },
      {
        path: 'sent',
        component: RequestSentComponent,
        pathMatch: 'full',
      },
      {
        path: 'change',
        component: ChangePasswordComponent,
        pathMatch: 'full',
        canActivate: [ChangePasswordGuard],
      },
      {
        path: 'complete',
        component: ResetPasswordCompleteComponent,
        pathMatch: 'full',
      },
      {
        path: 'failure',
        component: RequestFailureComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'confirm-email',
    component: EmailChangeConfirmationComponent,
    pathMatch: 'full',
    canActivate: [EmailChangeConfirmationGuard],
  },
];
