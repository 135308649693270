import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  OperatorFunction,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  SelectionState,
  SelectionStateV2,
  selectionStateV2ToSelectionState,
} from '../../features/grid/grid.component';
import { CellEditingStoppedEvent, ColDef, GridOptions } from '@ag-grid-community/core';
import { FolderKindsEnum } from '../folders/models/folderKinds';
import { DatabaseFolder, Folder, FolderTreeItem } from '../folders/models/folder.model';
import { DeterminateSelectionState, GridState } from '../../features/grid/grid.interfaces';
import { CleanUp } from '../../shared/cleanup';
import { ViewerPageURLSelectionState } from '../viewer-page/viewer-page.component';
import { fileTableDefs } from '../folders/models/colDefs';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { AlignmentComponent } from '../pipeline-dialogs/alignment/alignment.component';
import { NgsComparisonsComponent } from '../pipeline-dialogs/ngs-comparisons/ngs-comparisons.component';
import { AnnotationAlignmentComponent } from '../pipeline-dialogs/annotation-alignment/annotation-alignment.component';
import { AntibodyAnnotatorComponent } from '../pipeline-dialogs/antibody-annotator/antibody-annotator.component';
import { SetMergePairedReadsComponent } from '../pipeline-dialogs/set-merge-paired-reads/set-merge-paired-reads.component';
import { GroupSequencesComponent } from '../pipeline-dialogs/group-sequences/group-sequences.component';
import { BatchRenameComponent } from '../pipeline-dialogs/batch-rename/batch-rename.component';
import { BatchAssembleComponent } from '../pipeline-dialogs/batch-assemble/batch-assemble.component';
import { MotifAnnotatorComponent } from '../pipeline-dialogs/motif-annotator/motif-annotator.component';
import { ClientGridV2Component } from '../../features/grid/client-grid-v2/client-grid-v2.component';
import {
  getNucleusPipelineID,
  NucleusPipelineID,
  PipelineFormID,
} from '../pipeline/pipeline-constants';
import { RemoveUmiDuplicatesComponent } from '../pipeline-dialogs/remove-umi-duplicates/remove-umi-duplicates.component';
import { TrimEndsComponent } from '../pipeline-dialogs/trim-ends/trim-ends.component';
import { SingleCellAntibodyAnalysisComponent } from '../pipeline-dialogs/single-cell-antibody-analysis/single-cell-antibody-analysis.component';
import { PairChainsComponent } from '../pipeline-dialogs/pair-chains/pair-chains.component';
import { FolderInfoComponent } from '../folder-info/folder-info.component';
import { NgbModalRef, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FindHeterozygotesComponent } from '../pipeline-dialogs/find-heterozygotes/find-heterozygotes.component';
import {
  ViewerDocumentData,
  ViewerDocumentSelection,
} from '../viewer-components/viewer-document-data';
import { annotatedPluginDocumentViewerOverlays } from '../viewer-components/viewer-overlays';
import { PipelineItem } from '../dialogV2/pipelineItem.model';
import { FilesTableFacade } from './files-table.facade';
import { MasterDatabaseFormDialogComponent } from '../master-database/master-database-form-dialog/master-database-form-dialog.component';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PipelineDialogService } from '../pipeline-dialogs/pipeline-dialog.service';
import { ActivityStreamService } from '../activity/activity-stream.service';
import { DocumentActivityEventKind } from '../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrgProfileCheckService } from '../../shared/access-check/org-profile-check/org-profile-check.service';
import { AntibodyAnnotatorFreeComponent } from '../pipeline-dialogs/antibody-annotator/antibody-annotator-free.component';
import { PipelineAssociationsService } from '../pipeline/pipeline-associations/pipeline-associations.service';
import {
  IS_ASSOCIATE_ORG,
  IS_FREE_ORG,
} from '../../shared/access-check/access-check-condition.model';
import { PipelineSelectionSignaturesService } from '../pipeline-dialogs/pipeline-selection-signatures.service';
import {
  DocumentSelectionSignature,
  selectionSignatureMatches,
} from '../document-selection-signature/document-selection-signature.model';
import { ViewersService } from '../viewers-v2/viewers/viewers.service';
import { Router } from '@angular/router';
import { DocumentUtils } from '../document-utils';
import { NgsAntibodyAnnotatorComponent } from '../pipeline-dialogs/ngs-antibody-annotator/ngs-antibody-annotator.component';
import { AddClustersComponent } from '../pipeline-dialogs/add-clusters/add-clusters.component';
import { AntibodyAnnotatorOptionValues } from '../pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import {
  PeptideAndProteinAnnotatorComponent,
  PeptideAndProteinDialogData,
} from '../pipeline-dialogs/peptide-annotator/peptide-and-protein-annotator.component';
import { ToolstripComponent } from '../../shared/toolstrip/toolstrip.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { ToolstripItemComponent } from '../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { DisableIfDirective } from '../../shared/access-check/directives/disable/disable-if.directive';
import { UploadButtonDirective } from '../upload/upload-button.directive';
import { MatIconModule } from '@angular/material/icon';
import { FolderTreeSelectorComponent } from '../folder-tree-selector/folder-tree-selector.component';
import { ShowIfDirective } from '../../shared/access-check/directives/show/show-if.directive';
import { ExportToolstripItemComponent } from '../export/export-toolstrip-item/export-toolstrip-item.component';
import { HideIfDirective } from '../../shared/access-check/directives/hide/hide-if.directive';
import { BxPipelineChooserV2Component } from '../dialogV2/bx-pipeline-chooser-v2/bx-pipeline-chooser-v2.component';
import { AngularSplitModule } from 'angular-split';
import { ViewersStateDirective } from '../viewers-state/viewers-state.directive';
import { UploadDropDirective } from '../upload/upload-drop.directive';
import { GridSelectionStateDirective } from '../grid-selection-state/grid-selection-state.directive';
import { ViewersComponent } from '../viewers-v2/viewers/viewers.component';
import { OpenDocumentButtonComponent } from '../../shared/open-document-button/open-document-button.component';
import isComplexDocumentType = DocumentUtils.isComplexDocumentType;

@Component({
  selector: 'bx-files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss'],
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    NgbTooltip,
    DisableIfDirective,
    UploadButtonDirective,
    MatIconModule,
    NgbPopover,
    FolderTreeSelectorComponent,
    FormsModule,
    ReactiveFormsModule,
    ShowIfDirective,
    ExportToolstripItemComponent,
    HideIfDirective,
    BxPipelineChooserV2Component,
    AngularSplitModule,
    ViewersStateDirective,
    UploadDropDirective,
    NgClass,
    ClientGridV2Component,
    GridSelectionStateDirective,
    ViewersComponent,
    OpenDocumentButtonComponent,
    AsyncPipe,
  ],
})
export class FilesTableComponent extends CleanUp implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column flex-grow-1 overflow-hidden';

  @ViewChild(ClientGridV2Component, { static: true }) gridComponent: ClientGridV2Component;
  @ViewChild('moveDropdown') moveDropdown: NgbPopover;

  @Input() folder: Folder;
  @Input() selectRowIDs: string[] = [];
  // tableType used as a key for storing Table Preferences.
  @Input() tableType: string;
  @Input() showUpload = true;
  // TODO The Files Table top tool strip should be an injectable component? Or more configurable in some way.
  @Input() showCreateNewMasterDatabaseButton = false;
  @Output() selectRowIDsChanged = new EventEmitter<string[]>();

  readonly viewerStateKey = 'files-table-viewers';

  columnDefs: ColDef[];
  selected: SelectionStateV2;

  showPipelines: boolean;

  showMoveWidgetContainer = false;
  numberOfFiles = 0;

  // Viewer things.
  selectionStateV2$: Observable<SelectionStateV2>;
  viewerSelection$ = new Observable<ViewerDocumentSelection>();
  viewersData$: Observable<ViewerDocumentData>;
  isValidSelection$: Observable<boolean>;
  readonly viewersOverlays = annotatedPluginDocumentViewerOverlays;
  // How the table is currently sorted.
  gridState$ = new BehaviorSubject<GridState>({ columnsState: [] });
  gridOptions: GridOptions;

  openTabQueryParams$: Observable<ViewerPageURLSelectionState>;

  menuItems: PipelineMenuItems;
  exportAndMoveShown$: Observable<boolean>;
  exportDisabled$: Observable<boolean>;

  annotateDisabled$: Observable<boolean>;
  annotateHelpMessage$: Observable<string | null>;
  isDocumentEditButton$: Observable<boolean>;

  selectedFolderIDsControl = new FormControl<string[]>([]);

  readonly isFreeOrg = IS_FREE_ORG;
  readonly isAssociateOrg = IS_ASSOCIATE_ORG;

  private filesUploadedSubscription = Subscription.EMPTY;
  private deleteOnConfirmationDialogRef: NgbModalRef;
  private infoDialogRef: NgbModalRef;
  private createDatabaseDialogRef: NgbModalRef;
  private batchRenameDialogRef: NgbModalRef;
  private annotateDialogRef: NgbModalRef;
  private folder$ = new ReplaySubject<FolderTreeItem>();

  /** Events **/
  private selectionChangedEvent$ = new Subject<DeterminateSelectionState>();
  private quickRenameEvent$ = new Subject<QuickRenameEvent>();

  constructor(
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly dialogService: DialogService,
    private readonly pipelineDialogService: PipelineDialogService,
    private readonly activityStreamService: ActivityStreamService,
    private readonly filesTableFacade: FilesTableFacade,
    private readonly orgProfileCheckService: OrgProfileCheckService,
    private readonly selectionSignatureService: PipelineSelectionSignaturesService,
    private readonly pipelineAssociationService: PipelineAssociationsService,
    private readonly viewersService: ViewersService,
    private router: Router,
  ) {
    super();

    this.columnDefs = fileTableDefs;

    this.gridOptions = {
      suppressMultiSort: false,
      getContextMenuItems: (params) => {
        return [
          {
            name: 'Open Document',
            action: () => this.openDocument(params.node.data.id, false),
          },
          {
            name: 'Open Document in New Tab',
            action: () => this.openDocument(params.node.data.id, true),
          },
          {
            name: 'Rename Document',
            action: () => params.node.data && this.openSingleFileNameEditor(params.node.data.id),
          },
          'separator',
          'copy',
          'copyWithHeaders',
          'separator',
          'autoSizeThis',
          'autoSizeAll',
        ];
      },
    };
  }

  ngOnInit() {
    this.selectionStateV2$ = this.selectionChangedEvent$.pipe(
      map((state) => ({
        totalSelected: state.totalSelected,
        total: state.total,
        selectedRows: state.rows,
        rows: state.rows,
        ids: state.ids,
        selectAll: false,
      })),
      tap((state) => (this.selected = state)),
      startWith(new SelectionStateV2()),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.selectionChangedEvent$
      .pipe(skip(1))
      .subscribe((selection) => this.selectRowIDsChanged.emit(selection.ids));

    const selectedDocumentUpdated$ = this.selectionChangedEvent$.pipe(
      // Debounce to avoid subscribing to multiple websocket events too quickly.
      debounceTime(2000),
      filter((state) => state.totalSelected === 1),
      switchMap((state) => {
        const documentID = state.ids[0];
        return this.activityStreamService.listenToDocumentActivity(documentID).pipe(
          // Un-subscribe from websocket if the selection changes.
          // This will avoid any leaks from the websocket staying subscribed to particular document events on nucleus
          takeUntil(this.selectionChangedEvent$),
        );
      }),
      filter((activity) => activity.event.kind === DocumentActivityEventKind.DOCUMENT_UPDATED),
      startWith(<void>null),
    );

    this.viewerSelection$ = combineLatest([
      this.selectionChangedEvent$,
      selectedDocumentUpdated$,
    ]).pipe(
      switchMap(([state]) => {
        return this.filesTableFacade.getAPDs(state.ids, this.folder).pipe(
          map((apds) => {
            return {
              rows: apds,
              total: state.total,
              totalSelected: state.totalSelected,
            };
          }),
          // Don't constantly update the viewers with file changes on selection from the polling.
          // NOTE: Consider removing this if all viewers are handling constant updates.
          take(1),
        );
      }),
      startWith({
        rows: [],
        total: 0,
        totalSelected: 0,
      }),
    );
    this.openTabQueryParams$ = this.selectionStateV2$.pipe(
      map((state) => ({ selectAll: state.selectAll, folderID: this.folder.id, ids: state.ids })),
    );

    this.isDocumentEditButton$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('sequenceEditing'),
      this.selectionStateV2$,
      this.folder$,
    ]).pipe(
      map(
        ([isSequenceEditingEnabled, selection, folder]) =>
          isSequenceEditingEnabled &&
          selectionSignatureMatches(selection.rows, [
            DocumentSelectionSignature.forNucleotideSequences(1, 1000),
            DocumentSelectionSignature.forProteinSequences(1, 1000),
          ]) &&
          folder instanceof Folder,
      ),
    );

    this.viewersData$ = combineLatest([
      this.viewerSelection$,
      this.orgProfileCheckService.hasOrgProfileCategory('free'),
      this.openTabQueryParams$,
    ]).pipe(
      map(([viewerSelection, isFreeOrg, openQueryParams]) => {
        const containsComplexDocument = viewerSelection.rows.some((document) =>
          isComplexDocumentType(document.type),
        );
        return {
          selection: viewerSelection,
          isFreeOrg,
          isPreviewView: true,
          containsComplexDocument,
          openQueryParams,
        };
      }),
    );

    this.isValidSelection$ = this.viewersData$.pipe(
      map((data) => this.viewersService.verifyValidSelectionForViewers(data)),
    );

    this.quickRenameEvent$
      .pipe(withLatestFrom(this.filesTableFacade.files$), takeUntil(this.ngUnsubscribe))
      .subscribe(([renameEvent, files]) => {
        const document = files.find((file) => file.id === renameEvent.id);
        if (document && document.metadata.name !== renameEvent.value) {
          this.filesTableFacade.updateFileName(
            document.id,
            renameEvent.value,
            parseInt(document.metadata.blobRevision, 10),
          );
        }
      });

    this.menuItems = this.buildMenuItems();

    this.exportAndMoveShown$ = this.folder$.pipe(
      map((folder) => {
        const isDatabaseFolder = folder instanceof DatabaseFolder;
        const isProvidedDatabase = isDatabaseFolder ? (folder as DatabaseFolder).provided : false;
        return !isProvidedDatabase;
      }),
    );

    this.exportDisabled$ = this.selectionStateV2$.pipe(
      map((state) => state.totalSelected === 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.annotateDisabled$ = this.selectionStateV2$.pipe(
      map((state) => {
        const pipelineId = PipelineFormID.ANTIBODY_ANNOTATOR;
        const pipelineRegistered = this.selectionSignatureService.isPipelineRegistered(pipelineId);
        const hasRows = state.totalSelected > 0 && !!state.selectedRows;

        if (!this.canWrite() || !hasRows || !pipelineRegistered) {
          return true;
        }

        const pipelineItemAcceptedSignatures =
          this.selectionSignatureService.getPipelineSelectionSignatures(pipelineId);
        const signatureMatches = selectionSignatureMatches(
          state.rows,
          pipelineItemAcceptedSignatures,
        );
        return hasRows && !signatureMatches;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.annotateHelpMessage$ = this.annotateDisabled$.pipe(
      map((disabled) => {
        if (!disabled) {
          return null;
        }
        if (!this.canWrite()) {
          return 'This folder is read-only. Please contact the folder admin to obtain write privileges.';
        }
        return this.selectionSignatureService.getHelpMessage(PipelineFormID.ANTIBODY_ANNOTATOR);
      }),
    );
  }

  private openDocument(id: string, inNewTab: boolean) {
    const route = inNewTab ? '/view' : '/document';
    const urlTree = this.router.createUrlTree([route], {
      queryParams: {
        selectAll: false,
        folderID: this.folder.id,
        ids: [id],
      },
    });
    if (inNewTab) {
      const url = this.router.serializeUrl(urlTree);
      window.open(url, '_blank');
      return;
    }
    this.router.navigateByUrl(urlTree);
  }

  /**
   * Returns the pipeline items for each dropdown menu in {@link PipelineMenuItems}
   * @returns Menu items
   */
  private buildMenuItems(): PipelineMenuItems {
    const preProcessing$ = of<PipelineItem[]>([
      {
        id: PipelineFormID.PAIR_CHAINS,
        label: 'Pair Heavy/Light Chains...',
        component: PairChainsComponent,
      },
      {
        id: PipelineFormID.MERGE_PAIRED_READS,
        label: 'Set & Merge Paired Reads...',
        component: SetMergePairedReadsComponent,
      },
      {
        id: PipelineFormID.BATCH_RENAME,
        label: 'Batch Rename...',
        component: BatchRenameComponent,
      },
      {
        id: PipelineFormID.GROUP_SEQUENCES,
        label: 'Group Sequences...',
        component: GroupSequencesComponent,
      },
      {
        id: PipelineFormID.BATCH_ASSEMBLE,
        label: 'Batch Assemble Sanger Sequences...',
        component: BatchAssembleComponent,
      },
      {
        id: PipelineFormID.TRIM_ENDS,
        label: 'Trim Ends...',
        component: TrimEndsComponent,
      },
      {
        id: PipelineFormID.FIND_HETEROZYGOTES,
        label: 'Find Heterozygotes...',
        component: FindHeterozygotesComponent,
      },
    ]).pipe(
      switchMap((pipelineItems) =>
        forkJoin([
          this.featureSwitchService.isEnabledOnce('removeUMIDuplicates'),
          this.featureSwitchService.isEnabledOnce('barcodeSeparation'),
        ]).pipe(
          map(([addUmi, showBarcode]) => {
            if (addUmi) {
              const label = showBarcode
                ? 'Collapse UMI Duplicates & Separate Barcodes...'
                : 'Collapse UMI Duplicates...';
              pipelineItems.push({
                id: PipelineFormID.REMOVE_UMI_DUPLICATES,
                label,
                component: RemoveUmiDuplicatesComponent,
              });
            }
            return pipelineItems;
          }),
        ),
      ),
      this.removeUnassociatedPipelineItems(),
      startWith([]),
    );

    const annotation$: Observable<PipelineItem[]> = combineLatest([
      this.selectionStateV2$,
      this.featureSwitchService.isEnabledOnce('ngsAnnotator'),
      this.featureSwitchService.isEnabledOnce('singleCell'),
    ]).pipe(
      map(([selectionState, isNGSAnnotatorEnabled, isSingleCellEnabled]) => {
        const antibodyAnnotator = this.getAntibodyAnnotator(selectionState, isNGSAnnotatorEnabled);
        const ngsAnnotator = this.getNgsAnnotator(selectionState, isNGSAnnotatorEnabled);
        const singleCellAnnotator = this.getSingleCellAnnotator(
          selectionState,
          isSingleCellEnabled,
        );
        const proteinAnnotator = this.getProteinAnnotator(selectionState);
        const peptideAnnotator = this.getPeptideAnnotator(selectionState);

        return [
          antibodyAnnotator,
          ngsAnnotator,
          singleCellAnnotator,
          peptideAnnotator,
          proteinAnnotator,
        ].filter((annotator) => !!annotator);
      }),
      this.removeUnassociatedPipelineItems(),
      startWith([]),
    );

    // The document metadata for each row, note that some documents may not have any metadata on them.
    const metadataForAllSelectedRows$: Observable<any[]> = this.selectionStateV2$.pipe(
      map((selectionState) => selectionState.rows),
      map((rows) => rows?.map((row) => (row?.metadata ? row.metadata : undefined))),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // A list where each selected document has been mapped to its creation pipeline.
    const creationPipelines$: Observable<NucleusPipelineID[]> = metadataForAllSelectedRows$.pipe(
      map((rows) =>
        rows.map((row) => (row ? (row.originalAnnotatorPipeline as NucleusPipelineID) : undefined)),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // A list where each selected document has been mapped to its creation pipeline options
    const creationPipelinesOptions$: Observable<AntibodyAnnotatorOptionValues[]> =
      metadataForAllSelectedRows$.pipe(
        map((rows) =>
          rows.map((row) =>
            row?.antibodyAnnotatorOptionValues ? row.antibodyAnnotatorOptionValues : '{}',
          ),
        ),
        map((rowOptions) => rowOptions.map((rowOptions) => JSON.parse(rowOptions as string))), // nucleus provides json string of options
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    // True if any selected documents were created by the peptide or protein annotator.
    const containsPeptideResults$ = creationPipelinesOptions$.pipe(
      map((options) =>
        options.some((rowOptions) => rowOptions?.sequences_chain === 'genericSequence'),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const postProcessing$ = combineLatest([
      this.selectionStateV2$,
      creationPipelines$,
      creationPipelinesOptions$,
      containsPeptideResults$,
    ])
      .pipe(
        map(
          ([
            selectionState,
            creationPipelines,
            creationPipelinesOptions,
            containsPeptideResults,
          ]) => {
            return [
              {
                id: PipelineFormID.ALIGNMENT,
                label: 'Align...',
                component: AlignmentComponent,
              },
              {
                id: PipelineFormID.NGS_COMPARISON,
                label: 'Compare Results...',
                component: NgsComparisonsComponent,
                options: {
                  containsPeptideResults: containsPeptideResults,
                },
              },
              {
                id: PipelineFormID.ADD_CLUSTER,
                label: 'Add Clusters (Recluster)...',
                component: AddClustersComponent,
                disabled: this.addClusterDisabled(selectionState.ids, creationPipelines[0]),
                helpMessage: this.addClusterHelpMessage(selectionState.ids, creationPipelines[0]),
                options: {
                  documentID: selectionState.ids ? selectionState.ids[0] : undefined,
                  creationPipeline: creationPipelines[0],
                  creationPipelineOptions: creationPipelinesOptions[0],
                },
              },
              {
                id: PipelineFormID.MOTIF_ANNOTATOR,
                label: 'Discover Motifs (Alpha)...',
                component: MotifAnnotatorComponent,
              },
            ];
          },
        ),
      )
      .pipe(
        this.featureSwitchService.includeIfEnabled('alignAnchorByAnnotations', {
          id: PipelineFormID.ANNOTATION_ALIGNMENT,
          label: 'Align - Anchor by Annotations (Alpha)...',
          component: AnnotationAlignmentComponent,
        }),
        this.removeUnassociatedPipelineItems(),
        startWith([]),
      );

    return { preProcessing$, annotation$, postProcessing$ };
  }

  private getNgsAnnotator(
    selectionState: SelectionStateV2,
    ngsAnnotatorEnabled: boolean,
  ): PipelineItem {
    if (!ngsAnnotatorEnabled) {
      return undefined;
    }

    const limit = 500_000_000;

    const isNumberOfSequencesTooLarge =
      this.calculateNumberOfSelectedSequences(selectionState) > limit;

    const signatureMatches = this.doesSignatureMatch(
      selectionState,
      PipelineFormID.NGS_ANTIBODY_ANNOTATOR,
    );

    let helpMessage: string | undefined = undefined;
    if (!signatureMatches) {
      helpMessage = this.selectionSignatureService.getHelpMessage(
        PipelineFormID.NGS_ANTIBODY_ANNOTATOR,
      );
    } else if (isNumberOfSequencesTooLarge) {
      helpMessage = `NGS Antibody Annotator has a limit of ${limit} sequences.`;
    }

    return {
      id: PipelineFormID.NGS_ANTIBODY_ANNOTATOR,
      label: NgsAntibodyAnnotatorComponent.title + '...',
      component: NgsAntibodyAnnotatorComponent,
      disabled: isNumberOfSequencesTooLarge || !signatureMatches,
      helpMessage,
    };
  }

  private getAntibodyAnnotator(
    selectionState: SelectionStateV2,
    isNGSAnnotatorEnabled: boolean,
  ): PipelineItem {
    const isNumberOfSequencesTooLargeForAntibodyAnnotator =
      this.calculateNumberOfSelectedSequences(selectionState) > 20_000_000;

    const signatureMatches = this.doesSignatureMatch(
      selectionState,
      PipelineFormID.ANTIBODY_ANNOTATOR,
    );

    const isAADisabled =
      (isNGSAnnotatorEnabled && isNumberOfSequencesTooLargeForAntibodyAnnotator) ||
      !signatureMatches;

    let helpMessage: string | undefined = undefined;
    if (!signatureMatches) {
      helpMessage = this.selectionSignatureService.getHelpMessage(
        PipelineFormID.ANTIBODY_ANNOTATOR,
      );
    } else if (isNGSAnnotatorEnabled && isNumberOfSequencesTooLargeForAntibodyAnnotator) {
      helpMessage = `NGS Antibody Annotator is recommended for Illumina datasets and other documents with millions of sequences.`;
    }

    return {
      id: PipelineFormID.ANTIBODY_ANNOTATOR,
      label: 'Antibody Annotator...',
      component: AntibodyAnnotatorComponent,
      disabled: isAADisabled,
      helpMessage,
    };
  }

  private getSingleCellAnnotator(
    selectionState: SelectionStateV2,
    singleCellEnabled: boolean,
  ): PipelineItem {
    if (!singleCellEnabled) {
      return undefined;
    }

    const limit = 500_000_000;

    const isNumberOfSequencesTooLarge =
      this.calculateNumberOfSelectedSequences(selectionState) > limit;

    const signatureMatches = this.doesSignatureMatch(
      selectionState,
      PipelineFormID.SINGLE_CELL_ANTIBODY_ANALYSIS,
    );

    let helpMessage: string | undefined = undefined;
    if (!signatureMatches) {
      helpMessage = this.selectionSignatureService.getHelpMessage(
        PipelineFormID.SINGLE_CELL_ANTIBODY_ANALYSIS,
      );
    } else if (isNumberOfSequencesTooLarge) {
      helpMessage = `Single Cell Antibody Annotator has a limit of ${limit} sequences.`;
    }

    return {
      id: PipelineFormID.SINGLE_CELL_ANTIBODY_ANALYSIS,
      label: SingleCellAntibodyAnalysisComponent.title + '...',
      component: SingleCellAntibodyAnalysisComponent,
      disabled: isNumberOfSequencesTooLarge || !signatureMatches,
      helpMessage,
    };
  }

  private doesSignatureMatch(selectionState: SelectionStateV2, pipeline: PipelineFormID): boolean {
    const pipelineItemAcceptedSignatures =
      this.selectionSignatureService.getPipelineSelectionSignatures(pipeline);
    return selectionSignatureMatches(selectionState.rows, pipelineItemAcceptedSignatures);
  }

  private calculateNumberOfSelectedSequences(selectionState: SelectionStateV2) {
    return selectionState.selectedRows.reduce(
      (acc, row) => acc + parseInt((row.number_of_sequences as string) ?? '1'),
      0,
    );
  }

  private peptideAvailability(
    selectionState: SelectionStateV2,
  ): 'disabled' | 'unavailable' | 'enabled' {
    // check if peptide could be run (e.g. is it a sequence list)
    if (
      !selectionSignatureMatches(selectionState.rows, [
        DocumentSelectionSignature.forNucleotideSequences(1, 2147483647),
        DocumentSelectionSignature.forProteinSequences(1, 2147483647),
      ])
    ) {
      return 'unavailable';
    }
    // can run AA peptide
    if (
      selectionSignatureMatches(selectionState.rows, [
        DocumentSelectionSignature.forNucleotideSequences(
          1,
          PeptideAndProteinAnnotatorComponent.SANGER_LIMIT,
        ),
        DocumentSelectionSignature.forProteinSequences(
          1,
          PeptideAndProteinAnnotatorComponent.SANGER_LIMIT,
        ),
      ])
    ) {
      return 'enabled';
    }
    // can run NGS/single cell peptide
    return selectionSignatureMatches(selectionState.rows, [
      DocumentSelectionSignature.forNucleotideSequences(1, 2147483647),
    ])
      ? 'enabled'
      : 'disabled';
  }

  private getProteinAnnotator(selectionState: SelectionStateV2): PipelineItem {
    const peptideAvailability = this.peptideAvailability(selectionState);
    const proteinOptions: PeptideAndProteinDialogData = {
      title: 'Protein Annotator',
      pipelineId: 'protein-annotator',
      pipelineFormId: PipelineFormID.PROTEIN_ANNOTATOR,
    };
    return {
      id: PipelineFormID.PROTEIN_ANNOTATOR,
      label: 'Protein Annotator...',
      component: PeptideAndProteinAnnotatorComponent,
      options: proteinOptions,
      disabled: peptideAvailability !== 'enabled',
      helpMessage:
        peptideAvailability !== 'disabled'
          ? undefined
          : `Only available when < ${PeptideAndProteinAnnotatorComponent.SANGER_LIMIT} sequences are selected, or only nucleotide sequences are selected.`,
    };
  }

  private getPeptideAnnotator(selectionState: SelectionStateV2): PipelineItem {
    const peptideAvailability = this.peptideAvailability(selectionState);
    const peptideOptions: PeptideAndProteinDialogData = {
      title: 'Peptide Annotator',
      pipelineId: 'peptide-annotator',
      pipelineFormId: PipelineFormID.PEPTIDE_ANNOTATOR,
    };

    return {
      id: PipelineFormID.PEPTIDE_ANNOTATOR,
      label: 'Peptide Annotator...',
      component: PeptideAndProteinAnnotatorComponent,
      options: peptideOptions,
      disabled: peptideAvailability !== 'enabled',
      helpMessage:
        peptideAvailability !== 'disabled'
          ? undefined
          : `Only available when < ${PeptideAndProteinAnnotatorComponent.SANGER_LIMIT} sequences are selected, or only nucleotide sequences are selected.`,
    };
  }

  private addClusterDisabled(documentIDs: string[], creationPipeline: NucleusPipelineID): boolean {
    if (creationPipeline === 'recluster' || documentIDs.length > 1) {
      return true;
    } else {
      // Do not set to false here instead return undefined so that this will be decided based in PipelineSelectionSignaturesService
      return undefined;
    }
  }
  private addClusterHelpMessage(
    documentIDs: string[],
    creationPipeline: NucleusPipelineID,
  ): string {
    if (documentIDs.length > 1) {
      return 'Run this on exactly one Biologics Annotator Result';
    } else if (creationPipeline === 'recluster') {
      return 'Only runnable on the original Biologics Annotator Result';
    } else {
      return '';
    }
  }

  /**
   * Returns a RxJS mapping operator that takes an array of PipelineItems and
   * filters out any pipelines that cannot be run by the current user based on
   * their organization profile's pipeline associations.
   */
  private removeUnassociatedPipelineItems(): OperatorFunction<PipelineItem[], PipelineItem[]> {
    return switchMap((items: PipelineItem[]) =>
      this.pipelineAssociationService
        .getProfilePipelineAssociations()
        .pipe(
          map((associations) =>
            items.filter(
              (item) =>
                item.id === 'ngs-antibody-annotator' ||
                associations.includes(getNucleusPipelineID(item.id)),
            ),
          ),
        ),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const folder: SimpleChange = changes.folder;
    if (folder && folder.currentValue?.id !== folder.previousValue?.id) {
      this.updateFolder();
      this.resetData();
    }
    const select = changes.selectRowIDs;
    // Ignore empty values - the parent component clears the selectRowID queryParam on selection change
    if (select && select.currentValue !== select.previousValue) {
      this.gridComponent.selectRowsByIDWithRetry(select.currentValue);
    }
  }

  canWrite() {
    return this.folder && this.folder.hasWriteAccess();
  }

  canBatchRename(selected: SelectionStateV2): boolean {
    return selected.rows.every((doc) => doc.type === 'sequence' || doc.type === 'sequenceList');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.filesUploadedSubscription.unsubscribe();
    if (this.deleteOnConfirmationDialogRef) {
      this.deleteOnConfirmationDialogRef.dismiss();
    }
    if (this.batchRenameDialogRef) {
      this.batchRenameDialogRef.dismiss();
    }
    if (this.infoDialogRef) {
      this.infoDialogRef.dismiss();
    }
    if (this.createDatabaseDialogRef) {
      this.createDatabaseDialogRef.dismiss();
    }
    this.folder$.complete();
    this.gridState$.complete();
    this.selectionChangedEvent$.complete();
    this.quickRenameEvent$.complete();
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    this.quickRenameEvent$.next({ id: event.data.id, value: event.value });
  }

  updateFolder() {
    if (this.folder) {
      this.showPipelines = [
        FolderKindsEnum.EXPERIMENT,
        FolderKindsEnum.RESULT_FOLDER,
        FolderKindsEnum.FOLDER,
        FolderKindsEnum.NGS_RESULT_FOLDER,
        FolderKindsEnum.FOLDER,
      ].includes(this.folder.kind);
      this.folder$.next(this.folder);
    }
  }

  resetData() {
    this.selected = new SelectionStateV2();
  }

  handleSelectionChanged(selectionState: DeterminateSelectionState) {
    this.selectionChangedEvent$.next(selectionState);
  }

  renameSomeWay() {
    if (this.selected.totalSelected === 1) {
      // Hack to force editing to work; for some reason it doesn't anymore.
      this.gridComponent.gridOptions.columnApi.getColumn('name').getColDef().editable = true;
      // Edit inside the table using gaal lambdas.
      this.gridComponent.gridOptions.api.startEditingCell({
        colKey: 'name',
        rowIndex: this.gridComponent.gridOptions.api.getSelectedNodes()[0].rowIndex,
      });
    } else {
      // Show the batch rename dialog
      this.batchRenameDialogRef = this.pipelineDialogService.showDialog({
        component: BatchRenameComponent,
        folderID: this.folder.id,
        selected: selectionStateV2ToSelectionState(this.selected),
      });
    }
  }

  showFolderInfo() {
    return this.folder.kind === FolderKindsEnum.FOLDER;
  }

  moveWidgetMove() {
    const originFolder = this.folder.id;
    const targetFolder = this.selectedFolderIDsControl.value[0];
    const selectedList = this.selected.ids;
    const selectAll = this.selected.selectAll;

    if (originFolder !== targetFolder) {
      this.filesTableFacade.moveFiles(selectedList, selectAll, targetFolder);
    }
    this.moveDropdown.close();
  }

  remove() {
    this.deleteOnConfirmationDialogRef = this.dialogService.showConfirmationDialog({
      title: 'Delete documents permanently?',
      confirmationButtonText: 'Delete',
      confirmationButtonColor: 'danger',
    });

    this.deleteOnConfirmationDialogRef.result
      .then((confirmed) => {
        if (confirmed) {
          return this.filesTableFacade.removeRows(this.selected.ids, this.selected.selectAll);
        }
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
      })
      .catch(() => {});
  }

  openFolderInfoModal(): void {
    this.infoDialogRef = this.dialogService.showDialog({
      component: FolderInfoComponent,
      injectableData: {
        folder: this.folder,
      },
    });
  }

  get uploadMessage(): string {
    return this.folder && this.folder.features.canUpload ? 'Drop to upload files' : 'Upload denied';
  }

  handleGridStateChanged(event: GridState) {
    this.gridState$.next(event);
  }

  showCreateNewMasterDatabaseDialog() {
    this.createDatabaseDialogRef = this.pipelineDialogService.showDialog({
      component: MasterDatabaseFormDialogComponent,
      folderID: this.folder.id,
      selected: new SelectionState(),
      otherVariables: { creatingDatabase: true },
    });
  }

  showAnnotateDialog() {
    this.annotateDialogRef = this.pipelineDialogService.showDialog({
      component: AntibodyAnnotatorFreeComponent,
      folderID: this.folder.id,
      selected: selectionStateV2ToSelectionState(this.selected),
    });
  }

  private openSingleFileNameEditor(id: string) {
    // Hack to force editing to work; for some reason it doesn't anymore.
    this.gridComponent.gridOptions.columnApi.getColumn('name').getColDef().editable = true;
    // Edit inside the table using gaal lambdas.
    this.gridComponent.gridOptions.api.startEditingCell({
      colKey: 'name',
      rowIndex: this.gridOptions.api.getRowNode(id).rowIndex,
    });
  }
}

interface PipelineMenuItems {
  readonly preProcessing$: Observable<PipelineItem[]>;
  readonly annotation$: Observable<PipelineItem[]>;
  readonly postProcessing$: Observable<PipelineItem[]>;
}

interface QuickRenameEvent {
  id: string;
  value: string;
}
