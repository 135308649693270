import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { verifyAuthentication } from './auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectIsAuthenticatedAfterVerification } from './auth.selectors';
import { RedirectedURL } from './redirected-url';

/**
 * Protect internal application pages by checking if the User is authenticated on the server.
 * Redirects the User back to the login page otherwise.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private redirect: RedirectedURL,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    this.store.dispatch(verifyAuthentication());
    return this.store.pipe(selectIsAuthenticatedAfterVerification).pipe(
      map((authenticated) => {
        if (authenticated) {
          return true;
        } else {
          // If auth failed, but we were trying to go to something, save the redirect url so that the user gets
          // redirected to it after they've logged in.
          if (state.url && state.url.length > 0 && state.url !== '/') {
            this.redirect.evaluateAndUpdateRedirectUrl(state.url);
          }

          // This will navigate to the login page with the current routes query parameters. This is mainly for SSO errors that are set as
          // query parameters from Nucleus.
          // For some reason the navigationExtras parameter `queryParamsHandling: 'merge'` is not working and thus
          // the queryParams have to be set manually from the current route state.
          return this.router.createUrlTree(['/login'], { queryParams: route.queryParams });
        }
      }),
    );
  }
}
