import { EditorState } from './editor-state.enum';
import { createReducer, on } from '@ngrx/store';
import {
  cancelCreation,
  disableUser,
  forgetUser,
  setEditorState,
  updateUser,
} from './user-editor.actions';

export interface State {
  id: string;
  organizationId: string;
  email: string;
  givenName: string;
  familyName?: string;
  isAdmin: boolean;
  editorState: EditorState;
}

const initialState: State = {
  id: undefined,
  organizationId: undefined,
  email: undefined,
  givenName: undefined,
  familyName: undefined,
  isAdmin: false,
  editorState: EditorState.IDLE,
};

export const userEditorReducer = createReducer(
  initialState,
  on(setEditorState, (state, action) => ({
    ...state,
    editorState: action.state,
  })),
  on(disableUser, (state, { userID }) => ({
    ...state,
    id: userID,
    editorState: EditorState.PROCESSING,
  })),
  on(updateUser, (state, { user }) => ({
    ...state,
    id: user.id,
    organizationId: user.organizationId,
    email: user.email,
    givenName: user.givenName,
    familyName: user.familyName,
    isAdmin: user.isAdmin,
  })),
  on(forgetUser, (state) => ({
    ...state,
    id: undefined,
    email: undefined,
    givenName: undefined,
    familyName: undefined,
    isAdmin: undefined,
  })),
  on(cancelCreation, (state) => ({
    ...state,
    editorState: EditorState.IDLE,
  })),
);
