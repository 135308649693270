<form [formGroup]="dateRangeControl" class="form-inline w-100">
  <div class="row align-items-center">
    <div class="col">
      <p class="m-0">From</p>
    </div>
    <div class="col p-0">
      <input
        class="py-1 px-2 rounded border border-light-subtle form-control"
        id="startDate"
        type="datetime-local"
        formControlName="startDate"
        [max]="endDate"
      />
    </div>
    <div class="col">
      <p class="m-0">to</p>
    </div>
    <div class="col p-0">
      <input
        class="py-1 px-2 rounded border border-light-subtle form-control"
        id="endDate"
        type="datetime-local"
        formControlName="endDate"
        [min]="startDate"
        [max]="max"
      />
    </div>
    <div class="col p-0 ms-2">
      <button (click)="passDetails()" class="btn btn-primary">Search</button>
    </div>
  </div>
</form>
