import { Injectable } from '@angular/core';
import {
  AuditLogSearchParameters,
  AuditService,
  PageParameters,
} from '@geneious/nucleus-api-client';
import { Observable } from 'rxjs';
import { AuditInfoPaginatedResponse } from '@geneious/nucleus-api-client/model/audit-info-paginated-response';
import { DateRange } from './datepicker/datepicker.component';

@Injectable({
  providedIn: 'root',
})
export class AuditLogServiceService {
  constructor(private nucleusAuditLogService: AuditService) {}

  public fetchPage(
    dateRange: DateRange,
    pageParameters: PageParameters,
  ): Observable<AuditInfoPaginatedResponse> {
    const auditLogSearchParameters: AuditLogSearchParameters = {
      startTimestamp: dateRange.start.asISOString(),
      endTimestamp: dateRange.end.asISOString(),
      pageParameters: pageParameters,
    };
    return this.nucleusAuditLogService.searchAuditLogs(auditLogSearchParameters);
  }

  public exportPage(dateRange: DateRange): Observable<AuditInfoPaginatedResponse> {
    const auditLogSearchParameters: AuditLogSearchParameters = {
      startTimestamp: dateRange.start.asISOString(),
      endTimestamp: dateRange.end.asISOString(),
      pageParameters: {
        limit: 10000,
        offset: 0,
        includeTotal: false,
      },
    };
    return this.nucleusAuditLogService.searchAuditLogs(auditLogSearchParameters);
  }
}
