@if (isLumaConfigValid()) {
  <div class="alert alert-info" role="alert" data-spec="table-selection-info">
    Sending sequences from {{ banner.numRows }} row{{ banner.numRows === 1 ? '' : 's' }} of the
    {{ banner.table }} table
  </div>
} @else {
  <div class="alert alert-danger" role="alert" data-spec="luma-config-error">
    Luma configuration is incomplete.
    @if (userCanConfigureLuma()) {
      Please configure Luma
      <a [routerLink]="['/luma']" class="alert-link">here</a> before continuing.
    } @else {
      Please ask your administrator to update your organization's Luma configuration before
      continuing.
    }
  </div>
}
@if (projectsStatus() === 'error' || targetsStatus() === 'error') {
  <div class="alert alert-danger" role="alert" data-spec="luma-request-error">
    Failed to fetch data from Luma.
    @if (userCanConfigureLuma()) {
      Please check your Luma configuration
      <a [routerLink]="['/luma']" class="alert-link">here</a> and try again,
    } @else {
      Ask your administrator to check your organization's Luma configuration,
    }
    or <a [href]="helpCenterUrl" class="alert-link">contact support</a> for help.
  </div>
}

<form name="luma-options" [formGroup]="form">
  <bx-card headerTitle="Options">
    <div class="row mb-3">
      <label for="lumaSetName" class="col-md-3 col-lg-2 col-form-label" data-spec="set-name-label"
        >Set Name</label
      >
      <div class="col-md-9 col-lg-10">
        <input
          type="text"
          id="lumaSetName"
          formControlName="setName"
          data-spec="set-name-input"
          placeholder="Default"
          class="form-control"
        />
      </div>
    </div>

    <div class="row mb-3">
      <label for="format" class="col-md-3 col-lg-2 col-form-label d-flex" data-spec="format-label">
        Format
      </label>
      <div class="col-md-9 col-lg-10">
        <bx-select
          [formControl]="form.controls.format"
          [selectOptions]="formatOptions"
          [showFormErrors]="true"
          [canMarkPristineInvalid]="false"
          data-spec="format-select"
        ></bx-select>
      </div>
    </div>

    <div class="row mb-3">
      <label
        for="project"
        class="col-md-3 col-lg-2 col-form-label d-flex"
        data-spec="project-label"
      >
        Project
      </label>
      <div class="col-md-9 col-lg-10">
        <bx-select
          [formControl]="form.controls.project"
          [selectOptions]="projectsOptions()"
          [showFormErrors]="true"
          [loading]="projectsStatus() === 'loading'"
          [canMarkPristineInvalid]="false"
          data-spec="project-select"
        ></bx-select>
      </div>
    </div>

    <div class="row">
      <label for="target" class="col-md-3 col-lg-2 col-form-label d-flex" data-spec="target-label">
        Target
      </label>
      <div class="col-md-9 col-lg-10">
        <bx-select
          [formControl]="form.controls.target"
          [selectOptions]="targetsOptions()"
          [showFormErrors]="true"
          [loading]="targetsStatus() === 'loading'"
          [canMarkPristineInvalid]="false"
          data-spec="target-select"
        ></bx-select>
      </div>
    </div>
  </bx-card>
</form>
