import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnState,
  GridApi,
  GridOptions,
  IDatasource,
  RowEvent,
  RowGroupOpenedEvent,
  RowNode,
} from '@ag-grid-community/core';
import { GridService } from '../grid.service';
import { GridStateService } from '../../../core/grid-state/grid-state.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GridState } from '../grid.interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { CHECKBOX_COLUMN_STATE, CLIENT_CHECKBOX_COLUMN_DEF } from '../grid.constants';
import { ClientGridService } from './client-grid.service';
import { CUSTOM_OVERLAY_COMPONENTS } from '../client-grid-v2/client-grid-v2.defaults';
import { GridLoadingOverlayComponent } from '../overlays/grid-loading-overlay.component';
import { GridNoRowsOverlayComponent } from '../overlays/no-rows-overlay.component';

import { TotalSelectedComponent } from '../total-selected/total-selected.component';
import { GridModule } from '../grid.module';

/**
 * Displays dynamic columns/rows from given inputs.
 * Use this if you need to display all data at once without lazy pagination.
 */
@Component({
  selector: 'bx-client-grid',
  templateUrl: './client-grid.component.html',
  providers: [ClientGridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TotalSelectedComponent, GridModule],
})
export class ClientGridComponent extends CleanUp implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';

  @Input() rowData: any[];
  @Input() gridOptions?: GridOptions;
  @Input() columnDefs: (ColDef | ColGroupDef)[];
  @Input() detailColDefs?: ColDef[];
  @Input() tableType?: string;
  @Input() showTotalSelected = true;

  /**
   * An optional BehaviorSubject, any events emitted by this subject will set the quick filter
   * on this table.
   *
   * @type {BehaviorSubject<string>}
   * @default undefined
   */
  @Input() filter?: BehaviorSubject<string>;

  @Output() columnsChanged = new EventEmitter();
  @Output() rowDoubleClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter<ClientGridSelection>();

  totalNoOfRows: number;
  noOfRowsSelected: number;
  noOfSubTableRowsSelected: number;
  columns = new EventEmitter<Column[]>();

  private gridReady$: Subject<void>;

  constructor(
    private gridService: ClientGridService,
    private gridStateService: GridStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.totalNoOfRows = 0;
    this.noOfRowsSelected = 0;

    this.gridOptions = {
      // Default options
      noRowsOverlayComponent: 'noRowsOverlay',
      loadingOverlayComponent: 'loadingOverlay',

      // Up to parent component whether they want to pass in gridOptions or not.
      ...(this.gridOptions || {}),

      // Fixed options
      // Allow dots on field names.
      suppressFieldDotNotation: true,
      // Disable the right click menu.
      suppressContextMenu: true,
      suppressCopyRowsToClipboard: true,
      enableRangeSelection: true,
      // Stop columns from being deleted when they are dragged to the side of the grid.
      suppressDragLeaveHidesColumns: true,
      rowHeight: 22,
      rowSelection: 'multiple',
      // Disable "no sort". At least one column must always be sorted.
      sortingOrder: ['asc', 'desc'],
      rowGroupPanelShow: 'never',
      pivotMode: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      },
      maintainColumnOrder: true,
    };

    // Provide default overlays
    this.gridOptions.components = {
      noRowsOverlay: GridNoRowsOverlayComponent,
      loadingOverlay: GridLoadingOverlayComponent,
      ...(this.gridOptions.components ?? {}),
    };
    // Implement custom menu items.
    // Overrides Reset Columns so that side effects such as emitting the Grid State can be done.
    // https://www.ag-grid.com/javascript-grid-column-menu/
    this.gridOptions.getMainMenuItems = () => {
      return [
        'pinSubMenu',
        'separator',
        'autoSizeThis',
        'autoSizeAll',
        'separator',
        {
          name: 'Reset Columns State',
          action: () => this.resetToGridStateDefault(),
        },
      ];
    };

    this.gridReady$ = new Subject();

    this.gridReady$
      .pipe(
        filter(() => this.columnDefs && this.columnDefs.length > 0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.initializeGrid(this.gridOptions, this.columnDefs);
        this.updateAndEmitGridState();
        if (this.filter?.value) this.gridOptions.api.setQuickFilter(this.filter.value);
      });
    if (this.filter) {
      this.filter.pipe(takeUntil(this.ngUnsubscribe)).subscribe((newFilter) => {
        if (this.gridLoaded) {
          this.gridOptions.api.setQuickFilter(newFilter);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const filterString: SimpleChange = changes.filter;

    if (filterString && this.gridLoaded) {
      this.gridOptions.api.setQuickFilter(filterString.currentValue);
    }

    if (this.detailColDefs) {
      Object.assign(this.gridOptions, {
        suppressContextMenu: false,
        detailRowAutoHeight: true,
        detailCellRendererParams: {
          // If there are many rows then autoHeight can have performance problems.
          // Change this to use dynamicHeight if performance becomes an issue
          // https://www.ag-grid.com/javascript-grid-master-detail-height/#dynamic-height
          suppressRowClickSelection: false,
          detailGridOptions: {
            suppressRowClickSelection: false,
            // TODO Remove these in BX-5590.
            components: CUSTOM_OVERLAY_COMPONENTS,
            rowSelection: 'single',
            defaultColDef: {
              sortable: true,
              resizable: true,
              menuTabs: ['generalMenuTab', 'columnsMenuTab'],
            },
            getMainMenuItems: () => {
              return [
                'pinSubMenu',
                'separator',
                'autoSizeThis',
                'autoSizeAll',
                'separator',
                {
                  name: 'Reset Columns State',
                  action: () => this.resetToDetailGridStateDefault(),
                },
              ];
            },
          },
          getDetailRowData: function (params: any) {
            params.successCallback(params.data.matches);
          },
        },
      });
    }
    if (this.gridLoaded) {
      this.setColumnDefs(this.columnDefs);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.gridReady$.complete();
  }

  onRowGroupOpened(event: RowGroupOpenedEvent) {
    if (event.node.expanded) {
      const id = (event.node as RowNode).detailNode.id;
      const { api } = this.gridOptions.api.getDetailGridInfo(id);
      api.addEventListener('rowSelected', this.listener);
      api.addEventListener('columnPinned', this.updateAndEmitDetailGridState);
      api.addEventListener('columnResized', this.updateAndEmitDetailGridState);
      api.addEventListener('columnVisible', this.updateAndEmitDetailGridState);
      api.addEventListener('dragStopped', this.updateAndEmitDetailGridState);

      const detail = this.gridOptions.api.getDetailGridInfo(id);
      this.initializeGrid(detail, this.detailColDefs, true);
      this.updateAndEmitDetailGridState(detail);
    }
  }

  onSelectionChanged() {
    const selected = this.calculateSelection();
    this.selectionChanged.emit(selected);
  }

  /**
   * Set Row Data via AG-Grid API.
   * This is preferred for setting row data instead of the @Input rowData as it'll preserve
   * selection.
   *
   * @param rowData
   */
  setRowData(rowData: any[]) {
    // If Grid Loaded, set row data via grid API.
    if (this.gridLoaded) {
      this.preserveRowSelection(() => this.gridOptions.api.setRowData(rowData));
    } else {
      // Otherwise set initial row data via @Input.
      this.rowData = rowData;
    }
    if (rowData == null) {
      this.gridOptions.api?.showLoadingOverlay();
    } else {
      this.gridOptions.api?.hideOverlay();
    }
  }

  setColumnDefs(columnDefs: ColDef[]) {
    if (this.gridLoaded) {
      // Set Column Defs to Empty Array hack to get around the ag-grid breaking change https://github.com/ag-grid/ag-grid/issues/2771
      this.gridOptions.api.setColumnDefs([]);
      this.gridOptions.api.setColumnDefs(GridService.customizeColumnDefinitions(columnDefs));
    }
  }

  setColumnDefsForGrid(api: GridApi, columnDefs: ColDef[]) {
    api.setColumnDefs([]);
    api.setColumnDefs(GridService.customizeColumnDefinitions(columnDefs));
  }

  fullRefresh() {
    this.setRowData(this.rowData);
    this.setColumnDefs(this.columnDefs);
  }

  exportToCsv(fileName: string) {
    const options = { fileName };
    this.gridOptions.api.exportDataAsCsv(options);
  }

  setQuickFilter(filter: string) {
    this.gridOptions.api.setQuickFilter(filter);
  }

  get gridLoaded() {
    return this.gridOptions && this.gridOptions.api;
  }

  preserveRowSelection(callback: any) {
    const selected = this.gridOptions.api.getSelectedNodes().map((node) => node.data.id ?? node.id);
    callback();
    // Important to test for undefined rather than falsy because "0" is a valid id.
    const isSelected = (node: any) =>
      typeof selected.find((id) => id === (node.data.id ?? node.id)) !== 'undefined';
    const preserveSelection = (node: any) => (isSelected(node) ? node.setSelected(true) : null);
    this.gridOptions.api.forEachNode(preserveSelection);
    this.gridOptions.api.redrawRows();
    this.onSelectionChanged();
  }

  onGridReady() {
    this.refreshColumns();
    this.gridReady$.next();
  }

  onVisibilityChanged() {
    // todo client grid doesn't store information yet see BX-726
  }

  onGridColumnsChanged() {
    const columns = this.gridOptions.columnApi
      .getAllGridColumns()
      .filter((col) => col.getColDef().field !== 'selected');
    this.columnsChanged.emit(columns);
  }

  refreshColumns() {
    // TODO Remove the if statement and find out why columnApi isn't set some times.
    if (this.gridOptions && this.gridOptions.columnApi) {
      const columns = this.gridOptions.columnApi.getAllGridColumns();
      // This if statement covers a bug;
      // TODO Remove the if statement and find out why columns don't come back sometimes.
      if (columns) {
        // Don't send the checkbox / id column.
        const filtered = columns.filter((col) => col.getColDef().field !== 'selected');
        this.columns.emit(filtered);
      }
    }
  }

  onRowDataChanged() {
    this.totalNoOfRows = this.gridOptions.api.getModel().getTopLevelRowCount();
  }

  get subTableSelectionMessage() {
    if (this.noOfSubTableRowsSelected === 1) {
      return '1 match selected';
    } else if (this.noOfSubTableRowsSelected > 1) {
      return `${this.noOfSubTableRowsSelected} matches selected`;
    }
  }

  updateAndEmitGridState() {
    if (this.tableType) {
      const gridState = ClientGridComponent.getGridState(this.gridOptions);
      this.gridStateService.storeGridState(this.tableType, gridState);
    }
  }

  private updateAndEmitDetailGridState = (options: GridOptions) => {
    if (this.tableType) {
      const gridState = ClientGridComponent.getGridState(options);
      this.gridStateService.storeGridState(this.detailTableType, gridState);
    }
  };

  private calculateSelection(): ClientGridSelection {
    const selected: ClientGridSelection = { rows: [], subTableRows: [] };
    selected.rows = this.gridOptions.api.getSelectedRows();
    this.gridOptions.api.forEachDetailGridInfo((detail) => {
      const parentData = this.gridOptions.api.getRowNode(detail.id.split('detail_')[1]).data;
      const rows = detail.api.getSelectedRows().map((row) => ({
        parentData,
        ...row,
      }));
      selected.subTableRows.push(...rows);
    });

    this.totalNoOfRows = this.gridOptions.api.getModel().getTopLevelRowCount();
    this.noOfRowsSelected = selected.rows.length;
    this.noOfSubTableRowsSelected = selected.subTableRows.length;

    return selected;
  }

  private resetToGridStateDefault() {
    const allVisible: ColDef[] = this.removeSortState(this.gridService.originalColumnDefs);
    this.gridOptions.columnApi.resetColumnState();
    if (allVisible.length) {
      const columnsState = this.gridOptions.columnApi.getColumnState();
      const column = columnsState.find((column) => column.colId === allVisible[1].field);
      if (column) {
        this.gridOptions.columnApi.applyColumnState({
          state: columnsState,
          applyOrder: true,
          defaultState: { sort: null, sortIndex: null },
        });
      }
    }
    this.gridStateService.clearGridState(this.tableType);
  }

  private resetToDetailGridStateDefault() {
    const allVisible: ColDef[] = this.removeSortState(this.gridService.originalDetailColumnDefs);
    this.gridOptions.api.forEachDetailGridInfo((detail) => {
      detail.columnApi.resetColumnState();
      if (allVisible.length) {
        const columnsState = this.gridOptions.columnApi.getColumnState();
        const column = columnsState.find((column) => column.colId === allVisible[1].field);
        if (column) {
          this.gridOptions.columnApi.applyColumnState({
            state: columnsState,
            applyOrder: true,
            defaultState: { sort: null, sortIndex: null },
          });
        }
      }
      this.gridStateService.clearGridState(this.detailTableType);
    });
  }

  private removeSortState(colDefs: ColDef[]): ColDef[] {
    return colDefs.map((def: ColDef) => {
      if (def.colId === 'selected') {
        // TODO Invert dependency rather that require an if statement handle CHECKBOX_COLDEF outside
        // the map.
        return CLIENT_CHECKBOX_COLUMN_DEF;
      } else {
        // Remove any sort State.
        delete def.sort;
        delete def.sortIndex;
        return def;
      }
    });
  }

  private initializeGrid(grid: GridOptions, columnDefs: (ColDef | ColGroupDef)[], detail = false) {
    const immutableColDefs = columnDefs.map((colDef) => ({ ...colDef }));
    let tableType;
    if (detail) {
      this.gridService.updateOriginalDetailColumnDefs(immutableColDefs);
      tableType = this.detailTableType;
    } else {
      this.gridService.updateOriginalColumnDefs(immutableColDefs);
      tableType = this.tableType;
    }

    this.setColumnDefsForGrid(grid.api, immutableColDefs);
    this.gridStateService
      .getGridStateOrdered(tableType, grid.columnApi.getColumnState())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((gridState) => this.setGridState(gridState, grid, !detail));
  }

  private setGridState(gridState: GridState, options: GridOptions, sort: boolean = true): void {
    if (options && options.api) {
      const diff: ColumnState[] = ClientGridComponent.getGridState(options)
        .columnsState.filter(
          (masterColumnState) =>
            !gridState.columnsState.find(
              (columnState) => columnState.colId === masterColumnState.colId,
            ),
        )
        .map((masterColumnState) => ({ ...masterColumnState, ...{ hide: true } }));

      const columnsState = gridState.columnsState.concat(diff);
      options.columnApi.applyColumnState({
        // Always keep Checkbox Select column at the beginning pinned.
        state: [CHECKBOX_COLUMN_STATE, ...columnsState],
        applyOrder: true,
        defaultState: { sort: null, sortIndex: null },
      });
    }
  }

  private static getGridState(grid: GridOptions): GridState {
    const columnsState = grid.columnApi.getColumnState().filter((col) => col.colId !== 'selected');
    return { columnsState };
  }

  private get detailTableType() {
    if (this.tableType) {
      return `${this.tableType}_DETAIL`;
    }
  }

  private listener = (event: RowEvent) => {
    this.gridOptions.api.forEachDetailGridInfo((detail) => {
      detail.api.forEachNode((node) => {
        if (node.isSelected() && event.node.isSelected() && node !== event.node) {
          node.setSelected(false, undefined, 'api');
        }
      });
    });
    this.onSelectionChanged();
  };
}

export interface ClientGridSelection<T = any> {
  rows: T[];
  subTableRows: any[];
}
