import { ColDef } from '@ag-grid-community/core';

let LogColDefinitions: ColDef[];

LogColDefinitions = [
  {
    field: 'eventType',
    headerName: 'Event Type',
    sortable: false,
  },
  {
    field: 'timestamp',
    headerName: 'Time',
    sort: 'desc',
    sortable: false,
    pinned: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 400,
    maxWidth: 800,
    sortable: false,
  },
  {
    field: 'actingUserEmail',
    headerName: 'User Email',
    headerTooltip: 'Email of user who made this change.',
    sortable: false,
  },
  {
    field: 'affectedFolderID',
    headerName: 'Affected Folder',
    headerTooltip: 'ID of folder affected by this change.',
    sortable: false,
  },
  {
    field: 'affectedDocumentID',
    headerName: 'Affected Document',
    headerTooltip: 'ID of document affected by this change.',
    sortable: false,
  },
  {
    field: 'actingUserID',
    headerName: 'User ID',
    headerTooltip: 'ID of user who made this change.',
    sortable: false,
  },
  {
    field: 'actingUserIP',
    headerName: 'User IP',
    headerTooltip: 'IP of user who made this change.',
    sortable: false,
  },
];

export default LogColDefinitions;
