<ngb-alert type="info" [animation]="false" [dismissible]="false">
  <div class="">
    Importing
    <strong>
      {{ (this.allTablesAsOne$ | async)?.tables.length }} table{{
        (this.allTablesAsOne$ | async)?.tables.length === 1 ? '' : 's'
      }}
    </strong>
    from {{ this.files.length }} file{{ this.files.length === 1 ? '' : 's' }}
  </div>
</ngb-alert>

<div [formGroup]="form">
  @if ((allFilesContain1Table$ | async) === false) {
    <bx-card headerTitle="Import Options">
      <div class="form-check">
        <input
          type="checkbox"
          id="importEachTableSeparately"
          formControlName="importEachTableSeparately"
          name="importEachTableSeparately"
          class="form-check-input"
          ngFormControlValidator
        />
        <label
          for="importEachTableSeparately"
          class="form-check-label"
          ngbTooltip="If not selected, then files with multiple tables will be imported as a single document"
        >
          Import each table as an individual document
        </label>
      </div>
      <bx-form-errors [control]="this.form.controls.importEachTableSeparately"></bx-form-errors>
    </bx-card>
  }
</div>

<div class="h-static-3 overflow-y-auto d-flex">
  <bx-table-viewer [tablesToShow$]="allTablesAsOne$"> </bx-table-viewer>
</div>
