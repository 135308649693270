import { VersionEnum } from '@geneious/nucleus-api-client';
import { NGSDocumentTableSelection } from './exportOptions.model';
import { SelectionOptionsV1 } from './jobParameters.model';

export const LumaSequenceFormat = {
  IGG: 'IgG',
  FAB: 'Fab',
  SCFV: 'ScFv',
  VHH: 'Nanobody (VHH)',
  VHH_VHH: 'Nanobody (VHH-VHH)',
  TCR: 'TCR',
  OTHER: 'Other',
} as const;
export type LumaSequenceFormat = (typeof LumaSequenceFormat)[keyof typeof LumaSequenceFormat];

export interface RegisterSequencesLumaOptions extends NGSDocumentTableSelection {
  readonly setName?: string;
  readonly format: LumaSequenceFormat;
  readonly projectId: string;
  readonly targetId: string;
}

export interface RegisterSequencesLumaParameters {
  readonly selection: SelectionOptionsV1;
  readonly options: RegisterSequencesLumaOptions;
}

export interface RegisterSequencesLumaJobConfig {
  readonly pipeline: {
    readonly name: 'register-sequences-luma';
    readonly version: VersionEnum;
  };
  readonly parameters: RegisterSequencesLumaParameters;
}
