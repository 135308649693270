import { GridSelectionStates } from './grid-selection-state.interfaces';
import { createReducer, on } from '@ngrx/store';
import { gridSelectionStateUpdate } from './grid-selection-state.actions';

export const initialGridStates: GridSelectionStates = {};

export const gridSelectionStateReducer = createReducer(
  initialGridStates,
  on(gridSelectionStateUpdate, (state, { id, data }) => ({
    ...state,
    [id]: {
      totalSelected: data.totalSelected,
      total: data.total,
      ids: [...data.ids],
      // Rows needs to be stringified as they contain an unknown
      // depth of JSON Object nesting.
      rows: JSON.parse(JSON.stringify(data.rows)),
    },
  })),
);
