import { EMPTY, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatAll,
  exhaustMap,
  expand,
  map,
  toArray,
  tap,
  mergeMap,
} from 'rxjs/operators';
import {
  labelActions,
  loadOrganizationSettings,
  nameSchemeActions,
  OrgSettingsActionGroup,
  bioRegisterConfigurationActions,
  lumaConfigurationActions,
} from './organization-settings.actions';
import { authenticationSuccess, verifyAuthenticationSuccess } from '../auth/auth.actions';
import { OrganizationSettingsService } from '@geneious/nucleus-api-client';
import { PageTableResponse } from '../models/networking/page-response.model';
import { OrganizationSetting, OrganizationSettingKind } from '../models/settings/setting.model';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Injectable()
export class OrganizationSettingsEffects {
  private addSetting = (actionGroup: OrgSettingsActionGroup) =>
    createEffect(() =>
      this.actions.pipe(
        ofType(actionGroup.create),
        mergeMap(({ setting }) =>
          this.organizationSettingsService.createOrganizationSetting(setting).pipe(
            map((created) =>
              actionGroup.createSuccess({
                oldId: setting.id,
                newId: created.data.id,
              }),
            ),
            catchError(() =>
              observableOf(
                actionGroup.createFail({
                  failedSetting: setting,
                }),
              ),
            ),
          ),
        ),
      ),
    );

  private updateSetting = (actionGroup: OrgSettingsActionGroup) =>
    createEffect(() =>
      this.actions.pipe(
        ofType(actionGroup.update),
        mergeMap((action) =>
          this.organizationSettingsService
            .updateOrganizationSetting(action.settingToUpdate.id, {
              data: action.settingToUpdate.data,
              name: action.settingToUpdate.name,
            })
            .pipe(
              map((created) => {
                let setting = created.data;
                return actionGroup.updateSuccess({
                  updatedSetting: {
                    data: setting.data,
                    organizationID: setting.organizationID,
                    kind: setting.kind as OrganizationSettingKind,
                    name: setting.name,
                    id: setting.id,
                  },
                });
              }),
              catchError(() =>
                observableOf(
                  actionGroup.updateFail({
                    id: action.settingToUpdate.id,
                  }),
                ),
              ),
            ),
        ),
      ),
    );

  private showToastOnSettingsFailure = (
    actionGroup: OrgSettingsActionGroup,
    settingType: 'label' | 'name scheme' | 'bioregister' | 'Luma configuration',
  ) =>
    createEffect(
      () =>
        this.actions.pipe(
          ofType(actionGroup.createFail, actionGroup.updateFail, actionGroup.removeFail),
          map(() => {
            this.toastService.danger(
              `Something went wrong changing ${settingType} settings. Please try again later.`,
              { autohide: true },
            );
          }),
        ),
      { dispatch: false },
    );

  private showToastOnSettingsSuccess = (actionGroup: OrgSettingsActionGroup) =>
    createEffect(
      () =>
        this.actions.pipe(
          ofType(actionGroup.createSuccess, actionGroup.updateSuccess, actionGroup.removeSuccess),
          map((action) => {
            this.toastService.success('Successfully updated settings', {
              autohide: false,
              dismissible: true,
              delay: 2000,
              header: 'Update Successful',
            });
          }),
        ),
      { dispatch: false },
    );

  private navigateOnSettingSuccess = (actionGroup: OrgSettingsActionGroup, path: string) =>
    createEffect(
      () =>
        this.actions.pipe(
          ofType(actionGroup.createSuccess),
          tap((_) => {
            this.router.navigate([path]);
          }),
        ),
      { dispatch: false },
    );

  private deleteSetting = (actionGroup: OrgSettingsActionGroup) =>
    createEffect(() =>
      this.actions.pipe(
        ofType(actionGroup.remove),
        mergeMap(({ id }) =>
          this.organizationSettingsService.deleteOrganizationSetting(id).pipe(
            map(() => actionGroup.removeSuccess({ id })),
            catchError(() => observableOf(actionGroup.removeFail({ id }))),
          ),
        ),
      ),
    );

  addLabel = this.addSetting(labelActions);
  updateBioregisterSetting = this.updateSetting(bioRegisterConfigurationActions);
  addBioregisterSetting = this.addSetting(bioRegisterConfigurationActions);
  deleteBioregisterSetting = this.deleteSetting(bioRegisterConfigurationActions);
  onBioregisterActionFailure = this.showToastOnSettingsFailure(
    bioRegisterConfigurationActions,
    'bioregister',
  );
  onBioregisterActionSuccess = this.showToastOnSettingsSuccess(bioRegisterConfigurationActions);

  updateLumaSetting = this.updateSetting(lumaConfigurationActions);
  addLumaSetting = this.addSetting(lumaConfigurationActions);
  deleteLumaSetting = this.deleteSetting(lumaConfigurationActions);
  onLumaActionFailure = this.showToastOnSettingsFailure(
    lumaConfigurationActions,
    'Luma configuration',
  );

  onLumaActionSuccess = this.showToastOnSettingsSuccess(lumaConfigurationActions);
  onLabelActionFailure = this.showToastOnSettingsFailure(labelActions, 'label');
  deleteLabel = this.deleteSetting(labelActions);
  addNameScheme = this.addSetting(nameSchemeActions);
  onNameSchemeActionFailure = this.showToastOnSettingsFailure(nameSchemeActions, 'name scheme');
  deleteNameScheme = this.deleteSetting(nameSchemeActions);
  navigateOnAddNameSchemeSuccess = this.navigateOnSettingSuccess(
    nameSchemeActions,
    '/name-schemes',
  );

  loadOrganizationSettings = createEffect(() =>
    this.actions.pipe(
      ofType(authenticationSuccess, verifyAuthenticationSuccess),
      exhaustMap(({ userInfo }) => {
        const PAGE_SIZE = 1000;
        const source = (offset: number = 0) =>
          this.organizationSettingsService.listOrganizationSettings(
            userInfo.user.organizationID,
            undefined,
            offset,
            PAGE_SIZE,
            true,
          );
        return source(0).pipe(
          expand((response) => {
            const total = response.metadata.page.total;
            const offset = response.metadata.page.offset;
            const limit = offset + response.metadata.page.limit;
            const hasMorePages = total > limit;
            if (hasMorePages) {
              return source(offset + PAGE_SIZE).pipe(
                map((nextPage) => ({
                  data: response.data.concat(nextPage.data),
                  ...nextPage,
                })),
              );
            } else {
              return EMPTY;
            }
          }),
          map(
            (response) =>
              PageTableResponse.fromJson<OrganizationSetting>(response, OrganizationSetting).data,
          ),
          concatAll(),
          toArray(),
        );
      }),
      map((settings) => {
        const settingsMap: Record<string, OrganizationSetting> = {};
        settings.forEach(({ id, ...setting }) => {
          settingsMap[id] = { id, ...setting };
        });
        return loadOrganizationSettings({ settings: settingsMap });
      }),
    ),
  );

  constructor(
    private organizationSettingsService: OrganizationSettingsService,
    private actions: Actions,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
