import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { ActiveService } from '../active.service';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { logout } from '../auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class CookieInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private store: Store<AppState>,
    private activeService: ActiveService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't add any headers for non-nucleus routes.
    if (!req.url.startsWith(this.config.nucleusApiBaseUrl)) {
      return next.handle(req.clone({ withCredentials: false }));
    }

    const headers = {
      'X-BIOMATTERS-CSRF-PROTECTION': 'active',
    };
    const modified = req.clone({
      withCredentials: true,
      setHeaders: headers,
    });
    return next.handle(modified).pipe(
      map((event) => this.updateLastActivity(event)),
      catchError((err) => this.handleUnauthorized(err)),
    );
  }

  private updateLastActivity(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      // Every time we make a request we slide the cookie session expiration time.
      // So therefore we need to update our local record of last activity too.
      // `active.service` reads this value and uses it to determine if enough time has elapsed
      // since the last request to log us out.
      this.activeService.updateLatestActivity();
    }
    return event;
  }

  /**
   * Ideally this should never fire because `active.service` should know before the session
   * expires and log us out then.
   */
  private handleUnauthorized(error: any) {
    if (error instanceof HttpErrorResponse) {
      // NOTE: It's not OK to check auth state, if the user has been logged out but is still inside
      // the app then we need to redirect them out of the app. However if they are browsing external pages
      // (e.g. login, account setup, forgot password) then we don't want to redirect them to login.
      if (error.status === 401) {
        console.log('caught 401 in the interceptor');
        if (this.notAuth(error) && this.isFromNucleusRequest(error)) {
          this.store.dispatch(logout());
        }
      }
    }
    return observableThrowError(error);
  }

  /**
   * Prevent cyclic logout loops with 401 responses. (e.g. attempt to logout, get 401, attempt to logout..)
   */
  private notAuth(error: HttpErrorResponse) {
    return !error.url.includes('auth');
  }

  /**
   * Prevent 401 errors arising from non-nucleus requests from triggering logout
   */
  private isFromNucleusRequest(error: HttpErrorResponse) {
    const url = new URL(error.url);
    const nucleusBaseUrl = new URL(this.config.nucleusApiBaseUrl);
    return url.hostname.includes(nucleusBaseUrl.hostname);
  }
}
