<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'audit-log'], label: 'Audit Log' }]"
></bx-settings-breadcrumb>

<bx-toolstrip class="row g-0">
  <div class="col d-inline-flex">
    <bx-toolstrip-item>
      <div ngbDropdown>
        <button class="btn btn-light dropdown-toggle" ngbDropdownToggle>Export</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="handleExport('.json')">
            JSON <i> (first 10,000)</i>
          </button>
          <button ngbDropdownItem (click)="handleExport('.csv')">CSV <i> (first 10,000)</i></button>
        </div>
      </div>
    </bx-toolstrip-item>
  </div>
  <div class="col d-inline-flex justify-content-end p-0 m-0">
    <bx-toolstrip-item class="d-inline-flex align-items-end m-0 p-0">
      <bx-datepicker
        [startingDateRange]="datasourceParams$.getValue()"
        (newDateEmitter)="dateChanged($event)"
      ></bx-datepicker>
    </bx-toolstrip-item>
    <bx-toolstrip-item class="p-0 ps-3 m-0"
      ><button
        (click)="openHelpWindow($event)"
        title="Audit Log - Knowledge Base Article"
        class="btn btn-light"
      >
        <mat-icon class="bx-btn-icon">info</mat-icon>
      </button>
    </bx-toolstrip-item>
  </div>
</bx-toolstrip>

<as-split direction="vertical" unit="percent" [bxViewersState]="'audit-log'" [bxViewerIndex]="1">
  <as-split-area>
    <div class="d-flex h-100 overflow-hidden" data-testid="bx-grid-ready">
      <bx-grid
        [initialColumnDefs]="LogColDefinitions"
        [gridOptions]="gridOptions"
        tableType="audit-log"
        (selectionChanged)="handleSelection($event)"
        [datasource]="datasource"
        [datasourceParams$]="datasourceParams$"
        class="w-100"
      >
      </bx-grid>
    </div>
  </as-split-area>
  <as-split-area>
    @if (bumpoutEntry$.getValue()?.eventType) {
      <div class="d-flex justify-content-center">
        <div class="w-100 mx-3">
          <bx-card
            headerTitle="{{ addSpacingToCapitalizedWords(bumpoutEntry$.getValue().eventType) }}"
            class="m-3"
          >
            @for (colDef of LogColDefinitions; track colDef.field) {
              @if (bumpoutEntry$.getValue()[colDef.field]) {
                @switch (colDef.field) {
                  @case ('timestamp') {
                    <p class="my-1">
                      <b>{{ colDef.headerName }}:</b>
                      {{ bumpoutEntry$.getValue()[colDef.field] }}
                    </p>
                  }
                  @case ('eventType') {
                    <p class="my-1">
                      <b>{{ colDef.headerName }}:</b>
                      {{ addSpacingToCapitalizedWords(bumpoutEntry$.getValue()[colDef.field]) }}
                    </p>
                  }
                  @default {
                    <p class="my-1">
                      <b>{{ colDef.headerName }}:</b> {{ bumpoutEntry$.getValue()[colDef.field] }}
                    </p>
                  }
                }
              }
            }
          </bx-card>
        </div>
      </div>
    } @else {
      <div
        class="h-100 w-100 flex-grow-1 flex-shrink-1 overflow-hidden d-flex justify-content-center align-items-center"
      >
        <div class="text-center text-muted">
          <h3>Select Event to View Details</h3>
        </div>
      </div>
    }
  </as-split-area>
</as-split>
