import { Search } from './search.model';
import { saveReferrer, saveSearch } from './search.actions';
import { GlobalSearchTypes } from '../search-bar/search-bar.component';
import { createReducer, on } from '@ngrx/store';

const query = <Search.Query>{
  queryString: '',
  queryType: GlobalSearchTypes.FILE_FOLDER,
  pagination: {
    offset: 0,
    limit: 1000,
  },
};

// Currently results and searchInProgress parameters are unused.
const initialState: Search.State = {
  query: query,
  results: [],
  referrerPath: '/workspace',
  searchInProgress: false,
};

export const searchReducer = createReducer(
  initialState,
  on(saveSearch, (state, { query }) => ({
    ...state,
    query,
  })),
  on(saveReferrer, (state, { referrerPath }) => ({
    ...state,
    referrerPath,
  })),
);
