import {
  GraphControlTypeEnum,
  GraphDatasource,
  GraphDatasourceError,
  GraphSidebarControls,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
} from '../graph-sidebar';
import {
  AbaAllChartOptions,
  ClusterLengths,
  GraphAbaDataService,
  GraphOption,
} from '../graph-aba-data.service';
import { SummaryGraphParams } from '../../../core/ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { HeatmapInfo } from '../graph-heatmap/heatmap-info.model';
import { HeatmapChartOptions } from '../../report/report.model';
import {
  isChainCombinationsTable,
  isInexactClusterTable,
} from '../../../core/ngs/table-type-filters';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { hasClusterLengthColumn } from '../../../core/ngs/table-column-filters';
import { sortAntibodyRegionByName } from '../../../shared/sort.util';
import { firstValueFrom, of, ReplaySubject } from 'rxjs';

interface HeatmapSidebarOptions {
  region?: GraphOption;
  genes?: string;
  length?: string;
  table: boolean;
  wrapped?: boolean;
  reduced?: boolean;
  transposed?: boolean;
}
export default class AbaHeatmapGraphDatasource implements GraphDatasource {
  static readonly ALL_LENGTHS = 'All lengths';

  private lengths: ClusterLengths = [];
  private readonly regions: GraphOption[];
  private readonly geneCombinations: GraphOption[];
  private savedOptions: Partial<HeatmapSidebarOptions> = {};

  constructor(
    private dataService: GraphAbaDataService,
    private readonly documentID: string,
    private tables: Record<string, DocumentTable>,
    private params: SummaryGraphParams,
    private allOptions: AbaAllChartOptions,
    private initialOptions?: HeatmapChartOptions,
  ) {
    this.regions = this.allOptions.regions.filter(
      (region) => this.isValidRegion(region.name) && hasClusterLengthColumn(tables[region.table]),
    );

    if (this.params.source === 'codon') {
      this.regions = this.regions.filter((region) => !isInexactClusterTable(region));
    }

    const allCombinationsInChainCombinationsTable: GraphOption[] = Object.values(this.tables)
      .find((x) => isChainCombinationsTable(x))
      ?.columns.filter((col) => col.name.endsWith(' Gene') && col.name.includes('Heavy-Light'))
      .map(({ name }) => ({ name }) as GraphOption);
    if (this.params.source === 'json' && this.params.jsonChart === 'gene_combinations') {
      const allCombinations = [...this.allOptions.geneCombinations];
      for (const ccCombination of allCombinationsInChainCombinationsTable ?? []) {
        if (!allCombinations.find((combination) => combination.name === ccCombination.name)) {
          allCombinations.push(ccCombination);
        }
      }
      this.geneCombinations = allCombinations;
    } else {
      this.geneCombinations = [...(allCombinationsInChainCombinationsTable ?? [])];
    }
  }

  setInitialOptions(initialOptions: Record<string, any>) {
    if (initialOptions) {
      const region = this.regions.find((r) => r.name === initialOptions?.region);
      const geneCombination = this.geneCombinations.find(
        (gene) => gene.name === initialOptions?.genes,
      );
      this.initialOptions = {
        ...this.initialOptions,
        ...initialOptions,
        region,
        gene: geneCombination,
      };
      this.savedOptions = {
        ...initialOptions,
        region,
        genes: geneCombination?.name,
      };
    }
  }

  getIdForSavedOptions$() {
    return of({
      id: `chart-presenter-${this.documentID}-heatmap-${this.params?.source ?? ''}${this.params?.jsonChart ?? ''}`,
    });
  }

  validate(): GraphDatasourceError {
    if (this.params.source === 'codon' && !this.tables['DOCUMENT_TABLE_CODON_USAGE']) {
      return {
        controls: [],
        error:
          'There is no Codon Usage data for this result. To view this graph you will need to re-run your analysis.',
      };
    }
    if (!(this.initialOptions?.region ?? this.regions[0])) {
      return {
        controls: [],
        error:
          "No region clusters present. Your sequence filtering options may have been too restrictive for this data set. Please re-run analysis without the 'Only cluster' options turned on.",
      };
    }
    if (
      this.params.source === 'json' &&
      this.params.jsonChart === 'gene_combinations' &&
      !(this.initialOptions?.gene ?? this.geneCombinations[0])
    ) {
      return {
        controls: [],
        error:
          'There is no Gene Family Usage data for this result. To view this graph you will need to re-run your analysis.',
      };
    }
    return null;
  }

  async init(): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }
    const gene = this.initialOptions?.gene ?? this.geneCombinations[0];
    const initialRegion = this.initialOptions?.region ?? this.regions[0];
    let graphData: HeatmapInfo;
    try {
      graphData = await firstValueFrom(
        this.dataService.getHeatmapChartData(this.params, this.documentID, {
          length: this.initialOptions?.length ?? AbaHeatmapGraphDatasource.ALL_LENGTHS,
          region: initialRegion,
          geneCombination: gene,
          isTable: false,
          transposed: this.initialOptions?.transposed ?? false,
          reduced: this.initialOptions?.reduced ?? false,
        }),
      );
    } catch (error) {
      if (
        error.status === 400 &&
        error.url.includes('DOCUMENT_TABLE_CODON_USAGE') &&
        error.error.name === 'BadRequest'
      ) {
        return {
          controls: [],
          error:
            'There is no Codon Usage data for this result. To view this graph you will need to re-run your analysis.',
        };
      }
    }
    this.lengths = await this.fetchLengths(initialRegion);

    return this.formatData(
      this.regions,
      this.lengths,
      graphData,
      this.params,
      this.geneCombinations,
      {
        region: this.initialOptions?.region ?? this.regions[0],
        length: this.initialOptions?.length ?? AbaHeatmapGraphDatasource.ALL_LENGTHS,
        genes: gene?.name,
        table: false,
        wrapped: this.initialOptions?.wrapped ?? false,
        transposed: this.initialOptions?.transposed ?? false,
        reduced: this.initialOptions?.reduced ?? false,
        ...this.savedOptions,
      },
    );
  }

  async controlValueChanged(
    previousOptions: ControlOptions,
    options: ControlOptions,
  ): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }

    const length =
      previousOptions.region === options.region
        ? options.length
        : AbaHeatmapGraphDatasource.ALL_LENGTHS;
    const region = this.regions.find((r) => r.name === options.region);
    const genes = this.geneCombinations.find((gene) => gene.name === options.genes);

    const graphData = await firstValueFrom(
      this.dataService.getHeatmapChartData(this.params, this.documentID, {
        length: length,
        region: region,
        geneCombination: genes,
        isTable: options.table,
        transposed: options.transposed ?? false,
        reduced: options.reduced ?? false,
      }),
    );

    if (previousOptions.region !== options.region) {
      this.lengths = await this.fetchLengths(region);
    }

    return this.formatData(
      this.regions,
      this.lengths,
      graphData,
      this.params,
      this.geneCombinations,
      {
        region: region,
        length: length,
        genes: options.genes,
        table: options.table,
        wrapped: options.wrapped,
        reduced: options.reduced ?? false,
        transposed: options.transposed ?? false,
      },
    );
  }

  private formatData(
    regions: GraphOption[],
    lengths: (number | string)[],
    chart: HeatmapInfo,
    params: SummaryGraphParams,
    genes: GraphOption[],
    options: HeatmapSidebarOptions,
  ): GraphSidebarDatasourceResponse {
    const controls = this.generateControls(regions, lengths, chart, params, genes, options);
    if (chart.type === 'heatmap') {
      if (chart?.data?.series === undefined || chart?.data?.series?.data.length === 0) {
        const message = options.genes ?? `${options.region.name} with length ${options.length}`;
        return {
          error: `No sequences found for ${message}.`,
          controls,
        };
      }
      return {
        graph: {
          data: chart.data,
          title: chart.title,
          xAxisTitle: chart.xAxisTitle,
          yAxisTitle: chart.yAxisTitle,
          wrapped: options.wrapped,
          reduced: options.reduced,
          transposed: options.transposed,
          type: GraphTypeEnum.HEATMAP,
        },
        controls,
        options,
      };
    } else {
      return {
        graph: {
          data: chart.data,
          type: GraphTypeEnum.HEATMAP_TABLE,
        },
        controls,
        options,
      };
    }
  }

  private generateControls(
    regions: GraphOption[],
    lengths: (number | string)[],
    chart: HeatmapInfo,
    params: SummaryGraphParams,
    genes: GraphOption[],
    options: HeatmapSidebarOptions,
  ): GraphSidebarControls {
    const graphControls: GraphSidebarControls = [];
    if (params.source === 'json' && params.jsonChart === 'gene_combinations') {
      graphControls.push({
        name: 'genes',
        label: 'Genes',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.genes ?? genes[0].name,
        options: genes.map(({ name }) => {
          return {
            displayName: name,
            value: name,
          };
        }),
      });
    } else {
      regions.sort((a, b) => sortAntibodyRegionByName(a.name, b.name));
      graphControls.push(
        {
          name: 'region',
          label: 'Region',
          type: GraphControlTypeEnum.SELECT,
          defaultOption: options.region?.name ?? regions[0].name,
          options: regions.map(({ name }) => {
            return {
              displayName: name,
              value: name,
            };
          }),
        },
        {
          name: 'length',
          label: 'Length',
          type: GraphControlTypeEnum.SELECT,
          defaultOption: options.length ?? String(lengths[0]),
          options: lengths.map((length) => {
            return {
              displayName: `${length}`,
              value: `${length}`,
            };
          }),
        },
      );
    }

    graphControls.push({
      name: 'table',
      label: 'View Data as Table',
      type: GraphControlTypeEnum.CHECKBOX,
      defaultOption: options.table,
    });

    if (chart.type === 'heatmap') {
      graphControls.push({
        name: 'wrapped',
        label: 'Wrapped',
        section: 'Layout',
        type: GraphControlTypeEnum.CHECKBOX,
        defaultOption: options.wrapped,
      });
      if (chart.title.includes('Gene Combinations')) {
        graphControls.push({
          name: 'reduced',
          label: 'Group Genes into Families',
          type: GraphControlTypeEnum.CHECKBOX,
          defaultOption: options.reduced,
        });
        graphControls.push({
          name: 'transposed',
          label: 'Swap axes',
          section: 'Layout',
          type: GraphControlTypeEnum.CHECKBOX,
          defaultOption: options.transposed,
        });
      }
    }

    return graphControls;
  }

  private isValidRegion(regionName: string) {
    return (
      !regionName.toLowerCase().includes('gene') && !regionName.toLowerCase().includes('region')
    );
  }

  private async fetchLengths(region: GraphOption): Promise<ClusterLengths> {
    return this.params.source === 'codon'
      ? firstValueFrom(
          this.dataService.getFormattedClusterLengths(this.params.source, this.documentID, region),
        )
      : Promise.resolve([]);
  }
}

interface ControlOptions {
  region?: string;
  genes?: string;
  length?: string;
  table: boolean;
  wrapped?: boolean;
  reduced?: boolean;
  transposed?: boolean;
}
