import { Observable, of as observableOf } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Search } from './model/search.model';
import { Searcher } from './model/searcher';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { CleanUp } from '../../shared/cleanup';
import { FolderService } from '../folders/folder.service';
import { GlobalSearchTypes } from './search-bar/search-bar.component';
import { DocumentService } from '../../../nucleus/services/documentService/document-service.v1';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import { selectOrganizationID } from '../auth/auth.selectors';
import GridQueryParameters = Search.GridQueryParameters;
import { saveReferrer, saveSearch } from './model/search.actions';

/**
 * This service is responsible handling the UI search interactions and delegate requests to the transport layer.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService extends CleanUp implements Searcher {
  readonly SEARCH_RESULT_PATH = '/search-results';
  public isSearchInProgress$: Observable<boolean>;

  constructor(
    private folderService: FolderService,
    private documentService: DocumentService,
    private store: Store<AppState>,
  ) {
    super();
    this.isSearchInProgress$ = this.store
      .select('searchReducer')
      .pipe(map((state) => state.searchInProgress));
  }

  /**
   * Replaces old query with new search query in store.
   */
  public updateQuery(query: Search.Query, referrerPath: string): void {
    this.updateStore(query, referrerPath);
  }

  /**
   * Search with current search parameters (filter value), but potentially new sort value and pagination.
   * Designed for use with grids where the grid controls sort internally.
   *
   * @returns {Observable<Search.Result[]>}
   * @param params
   */
  public search(params: GridQueryParameters): Observable<IGridResourceResponse<Search.Result>> {
    return this.store.select('searchReducer').pipe(
      first(),
      switchMap((state: Search.State) => {
        // The grid passes through additional query parameters controlled by the grid, such as sort.
        const newQuery = Object.assign({}, state.query, params);
        this.updateQuery(newQuery, null);
        return newQuery.queryString === ''
          ? SearchService.emptySearch(newQuery)
          : this.query(newQuery);
      }),
    );
  }

  private query(query: Search.Query): Observable<IGridResourceResponse<Search.Result>> {
    if (query.queryType === GlobalSearchTypes.RESULT) {
      // Do CDR3 search
      return this.store.select(selectOrganizationID).pipe(
        first(),
        switchMap((orgID) =>
          this.documentService.queryTableSearch(orgID, query.queryString, query.sort),
        ),
        map((results) => SearchService.addUniqueIdentifier(results)),
      );
    } else {
      // Search folders and files.
      return this.folderService.searchChildren(query.queryString, query.sort, 1000).pipe(
        catchError((err) => {
          console.error(err);
          return observableOf({
            data: [],
            metadata: {
              total: 0,
              limit: query.pagination.limit,
              offset: query.pagination.offset,
            },
          });
        }),
      );
    }
  }

  private updateStore(query: Search.Query, referrerPath: string) {
    if (referrerPath && referrerPath.length > 0 && referrerPath !== this.SEARCH_RESULT_PATH) {
      this.store.dispatch(saveReferrer({ referrerPath }));
    }
    this.store.dispatch(saveSearch({ query }));
  }

  /**
   * Adds a unique identifier to each row if one does not already exist. Only works for non-paginated responses.
   */
  private static addUniqueIdentifier(
    results: IGridResourceResponse<Search.Result>,
  ): IGridResourceResponse<Search.Result> {
    results.data = results.data.map((row, index) =>
      Object.assign({ _bxUniqueIdentifier: index.toString() }, row),
    );
    return results;
  }

  /**
   * Mimics a search with no results - used when the search query is blank, as actually sending a blank query doesn't make sense.
   * @param {Search.Query} query
   * @returns {any}
   */
  private static emptySearch(query: Search.Query) {
    return observableOf({
      data: [],
      metadata: { limit: query.pagination.limit, offset: query.pagination.offset, total: 0 },
    });
  }
}
