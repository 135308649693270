import { first } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';
import { CurrentUserInfo } from '@geneious/nucleus-api-client';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectUserInfo } from '../auth/auth.selectors';
import { RedirectedURL } from '../auth/redirected-url';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { logout } from '../auth/auth.actions';

@Component({
  selector: 'bx-user-account-dialog',
  templateUrl: './user-account-dialog.component.html',
  styleUrls: ['./user-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, FaIconComponent, RouterLink, SpinnerButtonComponent, AsyncPipe],
})
export class UserAccountDialogComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block pb-1';
  @Output() readonly clickUserAccount = new EventEmitter<void>();

  userInfo$: Observable<CurrentUserInfo>;
  readonly faBuilding = faBuilding;
  loggingOut = false;

  constructor(
    private redirectedURL: RedirectedURL,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.userInfo$ = this.store.pipe(select(selectUserInfo), first());
  }

  logout() {
    this.loggingOut = true;
    // TODO Disconnect from selectedFolder here, and whenever user is automatically logged out.
    // We don't want to redirect back to the last URL if the user logged out manually.
    this.redirectedURL.clearRedirectURL();
    this.store.dispatch(logout());
  }
}
