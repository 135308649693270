import { Component, Inject } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { APP_CONFIG, AppConfig } from '../../../../app.config';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.store';
import { submitForgotPasswordRequest } from '../../../auth/auth.actions';

import { RouterLink } from '@angular/router';
import { SpinnerButtonComponent } from '../../../../shared/spinner-button/spinner-button.component';

@Component({
  selector: 'bx-request-change',
  templateUrl: './forgot-password.component.html',
  standalone: true,
  imports: [FormsModule, RouterLink, SpinnerButtonComponent],
})
export class ForgotPasswordComponent {
  email: string;
  submitting = false;
  showCancelButton = true;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private store: Store<AppState>,
  ) {
    this.showCancelButton = this.config.geneiousProduct !== 'prime';
  }

  public submit(requestChangeForm: NgForm) {
    if (requestChangeForm.valid) {
      this.submitting = true;
      this.store.dispatch(submitForgotPasswordRequest({ email: this.email }));
    }
  }
}
