import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  ActivityLogFolder,
  BioregisterFolder,
  DatabaseRootFolder,
  FolderTreeItem,
  GeneiousAdminFolder,
  GettingStartedFolder,
  LabelsFolder,
  LumaFolder,
  NameSchemeFolder,
  NonFolderTreeItem,
  OrganizationDatabaseFolder,
  SharedWorkspaceFolder,
  UploadsFolder,
  UserGroupsFolder,
  UsersFolder,
} from '../folders/models/folder.model';
import { combineLatest, EMPTY, forkJoin, Observable } from 'rxjs';
import { Router, RouterOutlet } from '@angular/router';
import { CleanUp } from '../../shared/cleanup';
import { FolderService } from '../folders/folder.service';
import { FolderKindsEnum } from '../folders/models/folderKinds';
import { FolderTreeService } from '../folder-tree/folder-tree.service';
import { HELP_CENTER_URL } from '../../app.constants';
import { environment } from '../../../environments/environment';
import { UploadsTableStoreService } from '../upload/uploads-table/uploads-table.store-service';
import { UploadStatus } from '../upload/uploads-table/uploads-table.reducer';
import { isNavigationEndEvent } from '../router/operators';
import { OrgProfileCheckService } from '../../shared/access-check/org-profile-check/org-profile-check.service';
import { DatabaseRootTypeEnum } from '../blast/database-root-type';
import { IS_FREE_ORG, IS_UNPAID_ORG } from '../../shared/access-check/access-check-condition.model';
import { IOutputData, AngularSplitModule } from 'angular-split';
import { select, Store } from '@ngrx/store';
import {
  selectAuditLoggingEnabled,
  selectIsAuthenticated,
  selectUserInfo,
  selectUserIsBiomattersStaff,
  selectUserIsNucleusAdmin,
  selectUserIsOrgAdmin,
  selectUserOrgDetails,
} from '../auth/auth.selectors';
import { AppState } from '../core.store';
import { fetchFolderChildren, replaceFolders } from '../folders/store/folder.actions';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { CursorMessageContainerComponent } from '../../shared/cursor-message/cursor-message-container.component';
import { LogoNavBarComponent } from '../navigation/logo-nav-bar/logo-nav-bar.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { ShowIfDirective } from '../../shared/access-check/directives/show/show-if.directive';
import { SearchComponent } from '../search/search/search.component';
import { MatIconModule } from '@angular/material/icon';
import { UserAccountDialogComponent } from '../user-account-dialog/user-account-dialog.component';
import { FolderTreeComponent } from '../folder-tree/folder-tree.component';
import { HideIfDirective } from '../../shared/access-check/directives/hide/hide-if.directive';
import { FolderTreeLinkComponent } from '../folder-tree-link/folder-tree-link.component';
import { JobTreeComponent } from '../jobs/job-tree/job-tree.component';
import { ActivityTreeComponent } from '../activity-tree/activity-tree.component';
import { AccessConditionTextPipe } from '../../shared/access-check/access-condition-text.pipe';

@Component({
  selector: 'bx-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
  standalone: true,
  imports: [
    CursorMessageContainerComponent,
    LogoNavBarComponent,
    NgClass,
    ShowIfDirective,
    SearchComponent,
    NgbPopover,
    MatIconModule,
    UserAccountDialogComponent,
    AngularSplitModule,
    FolderTreeComponent,
    HideIfDirective,
    FolderTreeLinkComponent,
    JobTreeComponent,
    ActivityTreeComponent,
    RouterOutlet,
    AsyncPipe,
    AccessConditionTextPipe,
  ],
})
export class SecureComponent extends CleanUp implements OnInit, OnDestroy {
  readonly isFreeOrg = IS_FREE_ORG;
  readonly isUnpaidOrg = IS_UNPAID_ORG;
  readonly sidebarWidthKey = 'sidebarWidth';
  readonly accountIcon = faUser;
  initialSidebarWidthPercent: number;
  initialMainContentWidthPercent: number;

  referenceDatabaseRoot$: Observable<FolderTreeItem>;
  sharedRoot$: Observable<FolderTreeItem>;
  usersRoot: NonFolderTreeItem;
  userGroupsRoot: NonFolderTreeItem;
  activityLogRoot: ActivityLogFolder;
  nameSchemesRoot: NonFolderTreeItem;
  labelsRoot: NonFolderTreeItem;
  bioRegister: NonFolderTreeItem;
  luma: LumaFolder;
  uploadsRoot: NonFolderTreeItem;
  geneiousAdmin: NonFolderTreeItem;
  gettingStartedPage: NonFolderTreeItem;
  bioregisterConfigEnabled$: Observable<boolean>;
  lumaConfigEnabled$: Observable<boolean>;

  helpLink: { label: string; link: string; icon: string };

  searchOpened$: Observable<boolean> = EMPTY;
  isNucleusAdmin$: Observable<boolean>;
  isAuditLogAvailable$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isBiomattersStaff$: Observable<boolean>;

  private readonly searchResultsURL = '/search-results';
  private uploadingStatus: UploadStatus;

  constructor(
    private router: Router,
    private folderTreeService: FolderTreeService,
    private store: Store<AppState>,
    private folderService: FolderService,
    private uploadsTableStoreService: UploadsTableStoreService,
    private orgProfileCheckService: OrgProfileCheckService,
    private modalService: NgbModal,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super();

    this.usersRoot = new UsersFolder();
    this.userGroupsRoot = new UserGroupsFolder();
    this.activityLogRoot = new ActivityLogFolder();
    this.uploadsRoot = new UploadsFolder();
    this.nameSchemesRoot = new NameSchemeFolder();
    this.bioRegister = new BioregisterFolder();
    this.luma = new LumaFolder();
    this.labelsRoot = new LabelsFolder();
    this.geneiousAdmin = new GeneiousAdminFolder();
    this.gettingStartedPage = new GettingStartedFolder();

    this.helpLink = { label: 'Help / Support', link: HELP_CENTER_URL, icon: 'live-help' };
    this.initialSidebarWidthPercent = 15;

    // TODO Move to service.
    // Used for changing the styling of the NavBar if global search page is open.
    this.searchOpened$ = router.events.pipe(
      isNavigationEndEvent(),
      map((event) => event.url === this.searchResultsURL),
    );

    // Listen to the latest upload status. Used for the refresh warning popup.
    this.uploadsTableStoreService.status$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        this.uploadingStatus = status;
      });

    console.log('Environment set to: ', environment);
  }

  // Warn the user with a confirmation pop-up on whether they want to refresh or not while
  // any file uploads are still in progress.
  @HostListener('window:beforeunload', ['$event'])
  showRefreshWarningPopup($event: BeforeUnloadEvent) {
    if (this.uploadingStatus === 'uploading') {
      $event.preventDefault();
      $event.returnValue = `You're still uploading! Your data may be lost.`;
    }
  }

  ngOnInit() {
    const storedWidth: number | { width: number } = JSON.parse(
      localStorage.getItem(this.sidebarWidthKey),
    );
    if (storedWidth) {
      // if the storedWidth has type 'number', it must be an old storedWidth in pixel units: it must be converted to percent
      this.initialSidebarWidthPercent =
        typeof storedWidth === 'number'
          ? (storedWidth * 100) / window.innerWidth
          : storedWidth.width;
    }
    this.initialMainContentWidthPercent = 100 - this.initialSidebarWidthPercent;
    this.isBiomattersStaff$ = this.store.pipe(select(selectUserIsBiomattersStaff), first());
    this.isNucleusAdmin$ = this.store.pipe(select(selectUserIsNucleusAdmin), first());
    this.isAuditLogAvailable$ = this.store.pipe(select(selectAuditLoggingEnabled), first());
    this.isAdmin$ = this.store.pipe(select(selectUserIsOrgAdmin), first());

    this.bioregisterConfigEnabled$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('registerSequencesBioregister'),
      this.isAdmin$,
    ]).pipe(map(([enabled, isAdmin]) => enabled && isAdmin));

    this.lumaConfigEnabled$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('lumaIntegration'),
      this.isAdmin$,
    ]).pipe(map(([enabled, isAdmin]) => enabled && isAdmin));

    this.folderService.selectedFolderID$
      .pipe(
        filter((selectedFolderID) => !!selectedFolderID),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((selectedFolderID) => {
        this.folderTreeService.expandToFolder(selectedFolderID);
      });

    this.store
      .pipe(
        select(selectIsAuthenticated),
        filter((authenticated) => authenticated === false),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.modalService.dismissAll();
      });

    this.loadFolderTree();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  loadFolderTree() {
    forkJoin([
      this.store.pipe(
        select(selectUserInfo),
        first((userInfo) => !!userInfo),
      ),
      this.orgProfileCheckService.hasOrgProfileCategoryOnce('free'),
    ]).subscribe(([{ sharedWorkspaceFolderID, referenceDatabaseFolderID, user }, isFreeOrg]) => {
      this.sharedRoot$ = this.folderService.get(sharedWorkspaceFolderID);

      // Free tier users have BLAST (Reference Sequences) at the top level instead of Organization Databases
      const orgRoot$ = this.folderService.get(referenceDatabaseFolderID);
      if (isFreeOrg) {
        this.referenceDatabaseRoot$ = orgRoot$.pipe(
          switchMap((root) => this.folderService.getChildren<DatabaseRootFolder>(root.id)),
          map((children) =>
            children.find((child) => child.databaseRootType === DatabaseRootTypeEnum.REFERENCE),
          ),
        );
      } else {
        this.referenceDatabaseRoot$ = orgRoot$;
      }

      const sharedRoot = new SharedWorkspaceFolder({
        id: sharedWorkspaceFolderID,
        organizationID: user.organizationID,
        name: isFreeOrg ? 'Workspace' : 'Shared Workspace',
        shared: !isFreeOrg,
      });
      sharedRoot.kind = FolderKindsEnum.SHARED_WORKSPACE;
      sharedRoot.parentID = 'virtual-root';

      const referenceDatabaseRoot = new OrganizationDatabaseFolder({
        id: referenceDatabaseFolderID,
        organizationID: user.organizationID,
      });
      referenceDatabaseRoot.kind = FolderKindsEnum.REFERENCE_DATABASES;
      referenceDatabaseRoot.parentID = 'virtual-root';

      this.store.dispatch(
        replaceFolders({
          parentID: sharedRoot.parentID,
          folders: [sharedRoot, referenceDatabaseRoot],
        }),
      );

      this.store.dispatch(fetchFolderChildren({ id: sharedWorkspaceFolderID }));
      this.store.dispatch(fetchFolderChildren({ id: referenceDatabaseFolderID }));
    });
  }

  onSidebarResize(event: IOutputData) {
    // the width is stored like this to distinguish between old saved widths in pixel units and new saved widths in percent units
    localStorage.setItem(this.sidebarWidthKey, JSON.stringify({ width: event.sizes[0] }));
  }
}
