import { formatDateForTables } from '../../shared/string-util';

export class DateTransformer {
  private readonly epochTime: number;

  private constructor(initialDate: number) {
    this.epochTime = initialDate;
  }

  // Factory pattern cause we are cool.

  public static fromDateTimeString(timestamp: DateTimeString) {
    return new DateTransformer(Date.parse(timestamp));
  }

  public static fromNucleusTimestamp(nucleusTimestamp: string) {
    return new DateTransformer(Date.parse(nucleusTimestamp));
  }

  public static fromDate(date: Date) {
    return new DateTransformer(date.getTime());
  }

  toOneWeekPrior(): DateTransformer {
    return new DateTransformer(this.epochTime - 7 * 24 * 60 * 60 * 1000);
  }

  asDateTimeString(): DateTimeString {
    const initialDate = new Date(this.epochTime);
    const date = initialDate.toLocaleDateString('fr-CA');
    const time = initialDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return `${date}T${time}` as DateTimeString;
  }

  asEpochTime(): number {
    return this.epochTime;
  }

  asISOString() {
    return new Date(this.asEpochTime()).toISOString();
  }

  asBXTableDateString() {
    return formatDateForTables(this.asISOString());
  }

  asFileName() {
    const date = new Date(this.asEpochTime());
    return (
      date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear().toString().slice(2)
    );
  }
}

type RegexMatchedString<Pattern extends RegExp> = string & { __regexPattern: Pattern };
const regex = new RegExp(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d$/gm);
export type DateTimeString = RegexMatchedString<typeof regex>;
